// React
import { useState, ChangeEvent, useEffect } from 'react';

// Styles
import './GeneratePage.scss';

// Components
import CoverLetterView from '../../components/CoverLetterView/CoverLetterView';
import {
  SignInToBegin,
  OutOfCredits,
} from '../../components/AlertDialog/AlertDialog';

// Hooks
import { useForm } from 'react-hook-form';
import { useUser } from '../../hooks/useUserData';
import { ErrorMessage } from '@hookform/error-message';

// PDF Parser
import * as pdfjsLib from 'pdfjs-dist';

interface FormInputs {
  title: string;
  company: string;
  description: string;
}

export default function GeneratePage() {
  const [fileName, setFileName] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [resumeText, setResumeText] = useState<string | null>(null);
  const [coverLetterContent, setCoverLetterContent] = useState<string>('');
  const [signInToBegin, setSignInToBegin] = useState<boolean>(false);
  const [outOfCredits, setOutOfCredits] = useState<boolean>(false);
  const [advancedOptions, setAdvancedOptions] = useState<boolean>(false);
  const [creativeSliderValue, setCreativeSliderValue] = useState(50);
  const [toneSliderValue, setToneSliderValue] = useState(50);
  const [lengthSliderValue, setLengthSliderValue] = useState(50);
  const [creativityLevel, setCreativityLevel] = useState('Moderately Creative');
  const [toneLevel, setToneLevel] = useState('Normal');
  const [lengthLevel, setLengthLevel] = useState('Medium');
  const { userData } = useUser();

  const {
    reset,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormInputs>({
    criteriaMode: 'all',
  });

  useEffect(() => {
    const _cvData = localStorage.getItem('CV');

    if (!_cvData) return;

    const _parsedCVData = JSON.parse(_cvData);

    setFileName(_parsedCVData.cvName);
    setResumeText(_parsedCVData.cvContent);
  }, []);

  // Set file name and parse pdf
  async function onFileChange(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files == null) {
      return;
    }

    const _cvName = event.target.files[0].name;

    setFileName(_cvName);

    setResumeText(null);

    const _pdf = event.target.files[0];

    const readFile = new FileReader();

    readFile.onload = function () {
      if (this.result == null || !(this.result instanceof ArrayBuffer)) {
        return;
      }

      const typedarray = new Uint8Array(this.result);

      pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

      const loadingTask = pdfjsLib.getDocument(typedarray);

      let textBuilder: string = '';

      loadingTask.promise
        .then(async (pdf: any) => {
          for (let i = 1; i <= pdf.numPages; i++) {
            const page = await pdf.getPage(i);
            const content = await page.getTextContent();
            const text = content.items
              .map((item: any) => {
                if (item.str) {
                  return item.str;
                }
                return '';
              })
              .join(' ');
            textBuilder += text;
          }

          const _cvContent = textBuilder.substring(0, 4999);

          setResumeText(_cvContent);

          localStorage.setItem(
            'CV',
            JSON.stringify({
              cvName: _cvName,
              cvContent: _cvContent,
            })
          );
        })
        .catch(() => {
          alert('AN ERROR OCCURRED WHILE PARSING THE PDF. PLEASE TRY AGAIN.');
        });
    };

    readFile.readAsArrayBuffer(_pdf);
  }

  // handle form submission
  const onSubmit = (data: FormInputs) => {
    if (!userData) {
      setSignInToBegin(true);

      return;
    }

    // destructure form data
    const { title, company, description } = data;

    // check if resume has been uploaded
    if (resumeText == null) {
      alert('Please upload a PDF copy of your CV!');
      return;
    }

    setIsLoading(true);
    startLoadingMessages();
    setAdvancedOptions(false);

    const payload = {
      title,
      company,
      description: description.substring(0, 4999),
      resumeText,
      creativityLevel,
      toneLevel,
      lengthLevel,
    };

    //send data to server
    fetch(`${process.env.REACT_APP_SERVER_URL}/generate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userData?.split(':')[0].substring(1)}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.message === 'NOCREDITS') {
          setOutOfCredits(true);
          setIsLoading(false);

          return;
        }

        if (data?.success === 'ok') {
          setCoverLetterContent(data.coverLetter);
          setIsLoading(false);
          setLoadingMessage('');
          reset({
            title: '',
            company: '',
            description: '',
          });
        }
      })
      .catch(() => {
        setIsLoading(false);
        alert(
          'AN ERROR OCCURRED WHILE GENERATING THE COVER LETTER. PLEASE TRY AGAIN LATER.'
        );
      });
  };

  const logOnFocus = (e: any) => {
    if (userData) return;

    setSignInToBegin(true);

    e.target.blur();
  };

  async function startLoadingMessages() {
    // scroll to bottom
    window.scrollTo(0, document.body.scrollHeight);

    setLoadingMessage('');

    setTimeout(() => {
      setLoadingMessage('Getting Started');
    }, 0);
    setTimeout(() => {
      setLoadingMessage('All Engines Running');
    }, 3500);
    setTimeout(() => {
      setLoadingMessage('Running A Few Checks');
    }, 7000);
    setTimeout(() => {
      setLoadingMessage('Almost There...');
    }, 11500);
    setTimeout(() => {
      setLoadingMessage('Stitching It All Together');
    }, 15000);
  }

  const onInputClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const element = event.target as HTMLInputElement;
    element.value = '';
  };

  const handleCreativeChange = (event: any) => {
    setCreativeSliderValue(event.target.value);
  };

  const handleToneChange = (event: any) => {
    setToneSliderValue(event.target.value);
  };

  const handleLengthChange = (event: any) => {
    setLengthSliderValue(event.target.value);
  };

  useEffect(() => {
    if (creativeSliderValue >= 0 && creativeSliderValue <= 19) {
      setCreativityLevel('Most Predictable');
    }
    if (creativeSliderValue >= 20 && creativeSliderValue <= 39) {
      setCreativityLevel('Somewhat Predictable');
    }
    if (creativeSliderValue >= 40 && creativeSliderValue <= 59) {
      setCreativityLevel('Moderately Creative');
    }
    if (creativeSliderValue >= 60 && creativeSliderValue <= 79) {
      setCreativityLevel('More Creative');
    }
    if (creativeSliderValue >= 80 && creativeSliderValue <= 100) {
      setCreativityLevel('Most Creative');
    }
  }, [creativeSliderValue]);

  useEffect(() => {
    if (toneSliderValue >= 0 && toneSliderValue <= 33) {
      setToneLevel('Casual');
    }
    if (toneSliderValue >= 34 && toneSliderValue <= 66) {
      setToneLevel('Professional');
    }
    if (toneSliderValue >= 67 && toneSliderValue <= 100) {
      setToneLevel('Formal');
    }
  }, [toneSliderValue]);

  useEffect(() => {
    if (lengthSliderValue >= 0 && lengthSliderValue <= 33) {
      setLengthLevel('Short');
    }
    if (lengthSliderValue >= 34 && lengthSliderValue <= 66) {
      setLengthLevel('Medium');
    }
    if (lengthSliderValue >= 67 && lengthSliderValue <= 100) {
      setLengthLevel('Long');
    }
  }, [lengthSliderValue]);

  return (
    <div className="main">
      <div className="main__hero">
        <h2>Create Covering Letter</h2>
        <p>
          Get a job in 2024 with a cover letter that's unique to you. Fill in
          the job details, upload your CV, provide the job description and let
          us do the work.
        </p>
      </div>

      <div className="main__container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h3>Job Details</h3>
          <div className="main__input">
            <div className="main__input-group">
              <input
                onFocus={logOnFocus}
                disabled={Boolean(isLoading)}
                placeholder="Job Title"
                type="text"
                id="title"
                {...register('title', {
                  required: 'Job title is required',
                  minLength: {
                    value: 2,
                    message: 'Min 2 letters',
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="title"
                render={({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <span className="input__error" key={type}>
                      {message}
                    </span>
                  ))
                }
              />
            </div>
            <div className="main__input-group">
              <input
                onFocus={logOnFocus}
                disabled={Boolean(isLoading)}
                placeholder="Company Name"
                type="text"
                id="company"
                {...register('company', {
                  required: 'Company name is required',
                  minLength: {
                    value: 1,
                    message: 'Min 1 letter',
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="company"
                render={({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <span className="input__error" key={type}>
                      {message}
                    </span>
                  ))
                }
              />
            </div>
          </div>

          <textarea
            onFocus={logOnFocus}
            disabled={Boolean(isLoading)}
            placeholder="Copy & Paste the Job Description"
            id="description"
            {...register('description', {
              required: 'Job description is required',
            })}
          ></textarea>
          <ErrorMessage
            errors={errors}
            name="description"
            render={({ messages }) =>
              messages &&
              Object.entries(messages).map(([type, message]) => (
                <span className="input__error" key={type}>
                  {message}
                </span>
              ))
            }
          />

          <label
            tabIndex={0}
            htmlFor="pdf"
            className={`btn${
              Boolean(isLoading) || !userData ? ' btn-disabled' : ''
            }`}
          >
            {!fileName ? 'Upload CV' : ''}
            <span>
              {fileName
                ? `Current CV: ${fileName}`
                : 'Provide a PDF copy of your CV'}
            </span>
          </label>
          <input
            style={{ display: 'none' }}
            id="pdf"
            name="pdf"
            type="file"
            accept="application/pdf"
            placeholder="pdf"
            onChange={onFileChange}
            onClick={onInputClick}
          />

          <div className="main__all-advanced">
            <div
              className="main__show-advanced"
              onClick={() => setAdvancedOptions(!advancedOptions)}
              style={{ pointerEvents: `${isLoading ? 'none' : 'auto'}` }}
            >
              <h2>Advanced Options</h2>
              {advancedOptions ? (
                <svg viewBox="0 -960 960 960">
                  <path d="m283-345-43-43 240-240 240 239-43 43-197-197-197 198Z" />
                </svg>
              ) : (
                <svg viewBox="0 -960 960 960">
                  <path d="M480-345 240-585l43-43 197 198 197-197 43 43-240 239Z" />
                </svg>
              )}
            </div>
            {advancedOptions && (
              <div className="main__advanced">
                <div className="main__advanced-slider">
                  <h3>
                    Creativity: <span>{creativityLevel}</span>
                  </h3>
                  <input
                    type="range"
                    min={0}
                    max={100}
                    value={creativeSliderValue}
                    onChange={handleCreativeChange}
                    className="main__input-slider"
                  />
                </div>
                <div className="main-advanced-extra">
                  <div className="main__advanced-slider">
                    <h3>
                      Tone: <span>{toneLevel}</span>
                    </h3>
                    <input
                      type="range"
                      min={0}
                      max={100}
                      value={toneSliderValue}
                      onChange={handleToneChange}
                      className="main__input-slider"
                    />
                  </div>
                  <div className="main__advanced-slider">
                    <h3>
                      Length: <span>{lengthLevel}</span>
                    </h3>
                    <input
                      type="range"
                      min={0}
                      max={100}
                      value={lengthSliderValue}
                      onChange={handleLengthChange}
                      className="main__input-slider"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          <button disabled={Boolean(isLoading)} type="submit">
            Create Cover Letter
          </button>

          {isLoading && (
            <div className="main__loading">
              <div className="lds-hourglass"></div>
              <p>{loadingMessage}</p>
            </div>
          )}
        </form>

        {coverLetterContent && (
          <CoverLetterView
            coverLetterData={coverLetterContent}
            isOpen={Boolean(coverLetterContent)}
            onClose={() => setCoverLetterContent('')}
          />
        )}

        {signInToBegin && (
          <SignInToBegin
            isOpen={signInToBegin}
            onClose={() => setSignInToBegin(false)}
          />
        )}

        {outOfCredits && (
          <OutOfCredits
            isOpen={outOfCredits}
            onClose={() => setOutOfCredits(false)}
          />
        )}
      </div>
    </div>
  );
}
