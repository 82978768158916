import './Privacy.scss';

import { useEffect } from 'react';

export default function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="privacy">
      <p className="c0">
        <span className="c1"></span>
      </p>
      <h1 className="c22" id="h.fznxgpf623a1">
        <span className="c30">Privacy Policy</span>
      </h1>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span>Our Privacy Policy was last updated on </span>
        <span className="c18">1st August 2023</span>
        <span className="c1">.</span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span>
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy.
        </span>
      </p>
      <h2 className="c3" id="h.ha5wlorkiosm">
        <span className="c13">Interpretation and Definitions</span>
      </h2>
      <h3 className="c15" id="h.mnujt3qhvpwc">
        <span className="c5">Interpretation</span>
      </h3>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </span>
      </p>
      <h3 className="c15" id="h.vbc17mbxffk5">
        <span className="c5">Definitions</span>
      </h3>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">For the purposes of this Privacy Policy:</span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_suxqkadflmqm-0 start">
        <li className="c4 c7 li-bullet-0">
          <span>&quot;</span>
          <span className="c2">Account</span>
          <span className="c1">
            &quot; means a unique account created for You to access our Service
            or parts of our Service.
          </span>
        </li>
      </ul>
      <p className="c0 c12">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_suxqkadflmqm-0">
        <li className="c4 c7 li-bullet-0">
          <span>&quot;</span>
          <span className="c2">Business</span>
          <span className="c1">
            &quot;, for the purpose of the CCPA (California Consumer Privacy
            Act), refers to the Company as the legal entity that collects
            Consumers&#39; personal information and determines the purposes and
            means of the processing of Consumers&#39; personal information, or
            on behalf of which such information is collected and that alone, or
            jointly with others, determines the purposes and means of the
            processing of consumers&#39; personal information, that does
            business in the State of California.
          </span>
        </li>
      </ul>
      <p className="c0 c12">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_suxqkadflmqm-0">
        <li className="c4 c7 li-bullet-0">
          <span>&quot;</span>
          <span className="c2">Company</span>
          <span>
            &quot; (referred to as either &quot;the Company&quot;,
            &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement)
            refers to{' '}
          </span>
          <span className="c18">
            MicroWeb
            <br />
            <br />
          </span>
          <span className="c1">
            For the purpose of the GDPR, the Company is the Data Controller.
          </span>
        </li>
      </ul>
      <p className="c0 c12">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_suxqkadflmqm-0">
        <li className="c4 c7 li-bullet-0">
          <span>&quot;</span>
          <span className="c2">Country</span>
          <span>&quot; refers to </span>
          <span className="c18">
            the United Kingdom of Great Britain and Northern Ireland
          </span>
          <span className="c1">.</span>
        </li>
      </ul>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_suxqkadflmqm-0">
        <li className="c4 c7 li-bullet-0">
          <span>&quot;</span>
          <span className="c2">Consumer</span>
          <span className="c1">
            &quot;, for the purpose of the CCPA (California Consumer Privacy
            Act), means a natural person who is a California resident. A
            resident, as defined in the law, includes (1) every individual who
            is in the USA for other than a temporary or transitory purpose, and
            (2) every individual who is domiciled in the USA who is outside the
            USA for a temporary or transitory purpose.
          </span>
        </li>
      </ul>
      <p className="c0 c12">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_suxqkadflmqm-0">
        <li className="c4 c7 li-bullet-0">
          <span>&quot;</span>
          <span className="c2">Cookies</span>
          <span className="c1">
            &quot; are small files that are placed on Your computer, mobile
            device or any other device by a website, containing the details of
            Your browsing history on that website among its many uses.
          </span>
        </li>
      </ul>
      <p className="c0 c12">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_suxqkadflmqm-0">
        <li className="c4 c7 li-bullet-0">
          <span>&quot;</span>
          <span className="c2">Data Controller</span>
          <span className="c1">
            &quot;, for the purposes of the GDPR (General Data Protection
            Regulation), refers to the Company as the legal person which alone
            or jointly with others determines the purposes and means of the
            processing of Personal Data.
          </span>
        </li>
      </ul>
      <p className="c0 c12">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_suxqkadflmqm-0">
        <li className="c4 c7 li-bullet-0">
          <span>&quot;</span>
          <span className="c2">Device</span>
          <span className="c1">
            &quot; means any device that can access the Service such as a
            computer, a cell phone or a digital tablet.
          </span>
        </li>
      </ul>
      <p className="c0 c12">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_suxqkadflmqm-0">
        <li className="c4 c7 li-bullet-0">
          <span>&quot;</span>
          <span className="c2">Do Not Track&quot; (DNT)&rdquo;</span>
          <span className="c1">
            &nbsp;is a concept that has been promoted by US regulatory
            authorities, in particular the U.S. Federal Trade Commission (FTC),
            for the Internet industry to develop and implement a mechanism for
            allowing internet users to control the tracking of their online
            activities across websites.
          </span>
        </li>
      </ul>
      <p className="c0 c12">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_suxqkadflmqm-0">
        <li className="c4 c7 li-bullet-0">
          <span>&quot;</span>
          <span className="c2">Personal Data</span>
          <span className="c1">
            &quot; is any information that relates to an identified or
            identifiable individual.
            <br />
            <br />
            For the purposes of GDPR, Personal Data means any information
            relating to You such as a name, an identification number, location
            data, online identifier or to one or more factors specific to the
            physical, physiological, genetic, mental, economic, cultural or
            social identity.
            <br />
            <br />
            For the purposes of the CCPA, Personal Data means any information
            that identifies, relates to, describes or is capable of being
            associated with, or could reasonably be linked, directly or
            indirectly, with You.
          </span>
        </li>
      </ul>
      <p className="c0 c12">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_suxqkadflmqm-0">
        <li className="c4 c7 li-bullet-0">
          <span>&quot;</span>
          <span className="c2">Sale</span>
          <span className="c1">
            &quot;, for the purpose of the CCPA (California Consumer Privacy
            Act), means selling, renting, releasing, disclosing, disseminating,
            making available, transferring, or otherwise communicating orally,
            in writing, or by electronic or other means, a Consumer&#39;s
            personal information to another business or a third party for
            monetary or other valuable consideration.
          </span>
        </li>
      </ul>
      <p className="c0 c12">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_suxqkadflmqm-0">
        <li className="c4 c7 li-bullet-0">
          <span>&quot;</span>
          <span className="c2">Service</span>
          <span className="c1">&quot; refers to the Website.</span>
        </li>
      </ul>
      <p className="c0 c12">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_suxqkadflmqm-0">
        <li className="c4 c7 li-bullet-0">
          <span>&quot;</span>
          <span className="c2">Service Provider</span>
          <span className="c1">
            &quot; means any natural or legal person who processes the data on
            behalf of the Company. It refers to third-party companies or
            individuals employed by the Company to facilitate the Service, to
            provide the Service on behalf of the Company, to perform services
            related to the Service or to assist the Company in analyzing how the
            Service is used. For the purpose of the GDPR, Service Providers are
            considered Data Processors.
          </span>
        </li>
      </ul>
      <p className="c0 c12">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_suxqkadflmqm-0">
        <li className="c4 c7 li-bullet-0">
          <span>&quot;</span>
          <span className="c2">Usage Data</span>
          <span className="c1">
            &quot; refers to data collected automatically, either generated by
            the use of the Service or from the Service infrastructure itself
            (for example, the duration of a page visit).
          </span>
        </li>
      </ul>
      <p className="c0 c12">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_suxqkadflmqm-0">
        <li className="c4 c7 li-bullet-0">
          <span>&quot;</span>
          <span className="c2">Website</span>
          <span>&quot; refers to </span>
          <span className="c18">CoverBot</span>
          <span>, accessible from </span>
          <span className="c1 c18">www.coverbot.net</span>
        </li>
      </ul>
      <p className="c0 c12">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_suxqkadflmqm-0">
        <li className="c4 c7 li-bullet-0">
          <span>&quot;</span>
          <span className="c2">You</span>
          <span className="c1">
            &quot; means the individual accessing or using the Service, or the
            company, or other legal entity on behalf of which such individual is
            accessing or using the Service, as applicable.
            <br />
            <br />
            Under GDPR (General Data Protection Regulation), You can be referred
            to as the Data Subject or as the User as you are the individual
            using the Service.
          </span>
        </li>
      </ul>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <h2 className="c3" id="h.mw7chom191o4">
        <span className="c13">Collecting and Using Your Personal Data</span>
      </h2>
      <h3 className="c15" id="h.og67v96jfydf">
        <span className="c5">Types of Data Collected</span>
      </h3>
      <h4 className="c8" id="h.ttu17anr1bya">
        <span className="c23">Personal Data</span>
      </h4>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_3tycahcz8l3h-0 start">
        <li className="c4 c7 li-bullet-0">
          <span className="c1">Email address</span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">First name and last name</span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">Phone number</span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">
            Address, State, Province, ZIP/Postal code, City
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">Usage Data</span>
        </li>
      </ul>
      <h4 className="c8" id="h.129dpb7nzamg">
        <span className="c23">Usage Data</span>
      </h4>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          Usage Data is collected automatically when using the Service.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          Usage Data may include information such as Your Device&#39;s Internet
          Protocol address (e.g. IP address), browser type, browser version, the
          pages of our Service that You visit, the time and date of Your visit,
          the time spent on those pages, unique device identifiers and other
          diagnostic data.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          When You access the Service by or through a mobile device, We may
          collect certain information automatically, including, but not limited
          to, the type of mobile device You use, Your mobile device unique ID,
          the IP address of Your mobile device, Your mobile operating system,
          the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          We may also collect information that Your browser sends whenever You
          visit our Service or when You access the Service by or through a
          mobile device.
        </span>
      </p>
      <h4 className="c8" id="h.5tqx8nu7trxk">
        <span className="c23">Tracking Technologies and Cookies</span>
      </h4>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          We use Cookies and similar tracking technologies to track the activity
          on Our Service and store certain information. Tracking technologies
          used are beacons, tags, and scripts to collect and track information
          and to improve and analyze Our Service. The technologies We use may
          include:
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_98hd9pt7g7bq-0 start">
        <li className="c4 c7 li-bullet-0">
          <span className="c2">Cookies or Browser Cookies.</span>
          <span className="c1">
            &nbsp;A cookie is a small file placed on Your Device. You can
            instruct Your browser to refuse all Cookies or to indicate when a
            Cookie is being sent. However, if You do not accept Cookies, You may
            not be able to use some parts of our Service. Unless you have
            adjusted Your browser setting so that it will refuse Cookies, our
            Service may use Cookies.
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c2">Web Beacons</span>
          <span className="c1">
            . Certain sections of our Service and our emails may contain small
            electronic files known as web beacons (also referred to as clear
            gifs, pixel tags, and single-pixel gifs) that permit the Company,
            for example, to count users who have visited those pages or opened
            an email and for other related website statistics (for example,
            recording the popularity of a certain section and verifying system
            and server integrity).
          </span>
        </li>
      </ul>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies.
          Persistent Cookies remain on Your personal computer or mobile device
          when You go offline, while Session Cookies are deleted as soon as You
          close Your web browser.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          We use both Session and Persistent Cookies for the purposes set out
          below:
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_xhjxrz3psbfv-0 start">
        <li className="c4 c7 li-bullet-0">
          <span className="c2">
            Necessary / Essential Cookies
            <br />
            <br />
          </span>
          <span className="c1">
            Type: Session Cookies
            <br />
            <br />
            Administered by: Us
            <br />
            <br />
            Purpose: These Cookies are essential to provide You with services
            available through the Website and to enable You to use some of its
            features. They help to authenticate users and prevent fraudulent use
            of user accounts. Without these Cookies, the services that You have
            asked for cannot be provided, and We only use these Cookies to
            provide You with those services.
          </span>
        </li>
      </ul>
      <p className="c0 c12">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_xhjxrz3psbfv-0">
        <li className="c4 c7 li-bullet-0">
          <span className="c2">
            Cookies Policy / Notice Acceptance Cookies
            <br />
            <br />
          </span>
          <span className="c1">
            Type: Persistent Cookies
            <br />
            <br />
            Administered by: Us
            <br />
            <br />
            Purpose: These Cookies identify if users have accepted the use of
            cookies on the Website.
          </span>
        </li>
      </ul>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_xhjxrz3psbfv-0">
        <li className="c4 c7 li-bullet-0">
          <span className="c2">
            Functionality Cookies
            <br />
            <br />
          </span>
          <span className="c1">
            Type: Persistent Cookies
            <br />
            <br />
            Administered by: Us
            <br />
            <br />
            Purpose: These Cookies allow us to remember choices You make when
            You use the Website, such as remembering your login details or
            language preference. The purpose of these Cookies is to provide You
            with a more personal experience and to avoid You having to re-enter
            your preferences every time You use the Website.
          </span>
        </li>
      </ul>
      <p className="c0 c12">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_xhjxrz3psbfv-0">
        <li className="c4 c7 li-bullet-0">
          <span className="c2">
            Tracking and Performance Cookies
            <br />
            <br />
          </span>
          <span className="c1">
            Type: Persistent Cookies
            <br />
            <br />
            Administered by: Third-Parties
            <br />
            <br />
            Purpose: These Cookies are used to track information about traffic
            to the Website and how users use the Website. The information
            gathered via these Cookies may directly or indirectly identify you
            as an individual visitor. This is because the information collected
            is typically linked to a pseudonymous identifier associated with the
            device you use to access the Website. We may also use these Cookies
            to test new pages, features or new functionality of the Website to
            see how our users react to them.
          </span>
        </li>
      </ul>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          For more information about the cookies we use and your choices
          regarding cookies, please visit our Cookies Policy or the Cookies
          section of our Privacy Policy.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <h2 className="c3" id="h.6s0wtslt7apx">
        <span className="c13">Use of Your Personal Data</span>
      </h2>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          The Company may use Personal Data for the following purposes:
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_x91o64mkwmi3-0 start">
        <li className="c4 c7 li-bullet-0">
          <span className="c2">To provide and maintain our Service</span>
          <span className="c1">
            , including to monitor the usage of our Service.
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c2">To manage Your Account:</span>
          <span className="c1">
            &nbsp;to manage Your registration as a user of the Service. The
            Personal Data You provide can give You access to different
            functionalities of the Service that are available to You as a
            registered user.
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c2">For the performance of a contract: </span>
          <span className="c1">
            the development, compliance and undertaking of the purchase contract
            for the products, items or services You have purchased or of any
            other contract with Us through the Service.
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c2">To contact You:</span>
          <span className="c1">
            &nbsp;To contact You by email, telephone calls, SMS, or other
            equivalent forms of electronic communication, such as a mobile
            application&#39;s push notifications regarding updates or
            informative communications related to the functionalities, products
            or contracted services, including the security updates, when
            necessary or reasonable for their implementation.
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c2">To provide You with news</span>
          <span className="c1">
            , special offers and general information about other goods, services
            and events which we offer that are similar to those that you have
            already purchased or enquired about unless You have opted not to
            receive such information.
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c2">To manage Your requests: </span>
          <span className="c1">To attend and manage Your requests to Us.</span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c2">For business transfers:</span>
          <span className="c1">
            &nbsp;We may use Your information to evaluate or conduct a merger,
            divestiture, restructuring, reorganization, dissolution, or other
            sale or transfer of some or all of Our assets, whether as a going
            concern or as part of bankruptcy, liquidation, or similar
            proceeding, in which Personal Data held by Us about our Service
            users is among the assets transferred.
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c2">For other purposes: </span>
          <span className="c1">
            We may use Your information for other purposes, such as data
            analysis, identifying usage trends, determining the effectiveness of
            our promotional campaigns and to evaluate and improve our Service,
            products, services, marketing and your experience.
          </span>
        </li>
      </ul>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          We may share Your personal information in the following situations:
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_372wecfibyc1-0 start">
        <li className="c4 c7 li-bullet-0">
          <span className="c2">With Service Providers</span>
          <span className="c1">
            : We may share Your personal information with Service Providers to
            monitor and analyze the use of our Service, for payment processing,
            to contact You.
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c2">For business transfers:</span>
          <span className="c1">
            &nbsp;We may share or transfer Your personal information in
            connection with, or during negotiations of, any merger, sale of
            Company assets, financing, or acquisition of all or a portion of Our
            business to another company.
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c2">With Affiliates:</span>
          <span className="c1">
            &nbsp;We may share Your information with Our affiliates, in which
            case we will require those affiliates to honor this Privacy Policy.
            Affiliates include Our parent company and any other subsidiaries,
            joint venture partners or other companies that We control or that
            are under common control with Us.
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c2">With business partners:</span>
          <span className="c1">
            &nbsp;We may share Your information with Our business partners to
            offer You certain products, services or promotions.
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c2">With other users: </span>
          <span className="c1">
            when You share personal information or otherwise interact in the
            public areas with other users, such information may be viewed by all
            users and may be publicly distributed outside.
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c2">With Your consent:</span>
          <span className="c1">
            &nbsp;We may disclose Your personal information for any other
            purpose with Your consent.
          </span>
        </li>
      </ul>
      <h2 className="c3" id="h.848dzk7m7qa7">
        <span className="c13">Retention of Your Personal Data</span>
      </h2>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          The Company will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          The Company will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter period of
          time, except when this data is used to strengthen the security or to
          improve the functionality of Our Service, or We are legally obligated
          to retain this data for longer time periods.
        </span>
      </p>
      <h2 className="c3" id="h.3k707f57642x">
        <span className="c13">Transfer of Your Personal Data</span>
      </h2>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          Your information, including Personal Data, is processed at the
          Company&#39;s operating offices and in any other places where the
          parties involved in the processing are located. It means that this
          information may be transferred to &mdash; and maintained on &mdash;
          computers located outside of Your state, province, country or other
          governmental jurisdiction where the data protection laws may differ
          than those from Your jurisdiction.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your agreement to that transfer.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          The Company will take all steps reasonably necessary to ensure that
          Your data is treated securely and in accordance with this Privacy
          Policy and no transfer of Your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of Your data and other personal information.
        </span>
      </p>
      <h2 className="c3" id="h.4xo7k1k0mzpo">
        <span className="c13">Disclosure of Your Personal Data</span>
      </h2>
      <h3 className="c15" id="h.gujpckreizka">
        <span className="c5">Business Transactions</span>
      </h3>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          If the Company is involved in a merger, acquisition or asset sale,
          Your Personal Data may be transferred. We will provide notice before
          Your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </span>
      </p>
      <h3 className="c15" id="h.d7bvp9o3lty7">
        <span className="c5">Law enforcement</span>
      </h3>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <h3 className="c15" id="h.voas3sgrnp4z">
        <span className="c5">Other legal requirements</span>
      </h3>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          The Company may disclose Your Personal Data in the good faith belief
          that such action is necessary to:
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_dbqzrg2bnrrq-0 start">
        <li className="c4 c7 li-bullet-0">
          <span className="c1">Comply with a legal obligation</span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">
            Protect and defend the rights or property of the Company
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">
            Prevent or investigate possible wrongdoing in connection with the
            Service
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">
            Protect the personal safety of Users of the Service or the public
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">Protect against legal liability</span>
        </li>
      </ul>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <h2 className="c3" id="h.f87kcjjnrq13">
        <span className="c13">Security of Your Personal Data</span>
      </h2>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
        </span>
      </p>
      <h2 className="c3" id="h.xphhakdrd9u8">
        <span className="c13">
          Detailed Information on the Processing of Your Personal Data
        </span>
      </h2>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          The Service Providers We use may have access to Your Personal Data.
          These third-party vendors collect, store, use, process and transfer
          information about Your activity on Our Service in accordance with
          their Privacy Policies.
        </span>
      </p>
      <h3 className="c15" id="h.sbbxqb9t5hhp">
        <span className="c5">Analytics</span>
      </h3>
      <p className="c4">
        <span className="c1">
          We may use third-party Service providers to monitor and analyze the
          use of our Service.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>

      <h3 className="c15" id="h.cjv5zirtr58m">
        <span className="c5">Email Marketing</span>
      </h3>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          We may use Your Personal Data to contact You with newsletters,
          marketing or promotional materials and other information that may be
          of interest to You. You may opt-out of receiving any, or all, of these
          communications from Us by following the unsubscribe link or
          instructions provided in any email We send or by contacting Us.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <h3 className="c15" id="h.qp05uhjsreu3">
        <span className="c5">Payments</span>
      </h3>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          We may provide paid products and/or services within the Service. In
          that case, we may use third-party services for payment processing
          (e.g. payment processors).
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          We will not store or collect Your payment card details. That
          information is provided directly to Our third-party payment processors
          whose use of Your personal information is governed by their Privacy
          Policy. These payment processors adhere to the standards set by
          PCI-DSS as managed by the PCI Security Standards Council, which is a
          joint effort of brands like Visa, Mastercard, American Express and
          Discover. PCI-DSS requirements help ensure the secure handling of
          payment information.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <h2 className="c3" id="h.iysgpxmdwaao">
        <span className="c13">GDPR Privacy Policy</span>
      </h2>
      <h3 className="c15" id="h.t0rkme7h698q">
        <span className="c5">
          Legal Basis for Processing Personal Data under GDPR
        </span>
      </h3>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          We may process Personal Data under the following conditions:
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_eywo5t908cpf-0 start">
        <li className="c4 c7 li-bullet-0">
          <span className="c2">Consent</span>
          <span className="c1">
            : You have given Your consent for processing Personal Data for one
            or more specific purposes.
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c2">Performance of a contract</span>
          <span className="c1">
            : Provision of Personal Data is necessary for the performance of an
            agreement with You and/or for any pre-contractual obligations
            thereof.
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c2">Legal obligations</span>
          <span className="c1">
            : Processing Personal Data is necessary for compliance with a legal
            obligation to which the Company is subject.
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c2">Vital interests</span>
          <span className="c1">
            : Processing Personal Data is necessary in order to protect Your
            vital interests or of another natural person.
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c2">Public interests</span>
          <span className="c1">
            : Processing Personal Data is related to a task that is carried out
            in the public interest or in the exercise of official authority
            vested in the Company.
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c2">Legitimate interests</span>
          <span className="c1">
            : Processing Personal Data is necessary for the purposes of the
            legitimate interests pursued by the Company.
          </span>
        </li>
      </ul>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          In any case, the Company will gladly help to clarify the specific
          legal basis that applies to the processing, and in particular whether
          the provision of Personal Data is a statutory or contractual
          requirement, or a requirement necessary to enter into a contract.
        </span>
      </p>
      <h3 className="c15" id="h.5p6zaum2uh16">
        <span className="c5">Your Rights under the GDPR</span>
      </h3>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          The Company undertakes to respect the confidentiality of Your Personal
          Data and to guarantee You can exercise Your rights.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          You have the right under this Privacy Policy, and by law if You are
          within the EU, to:
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_miqi16wgybsc-0 start">
        <li className="c4 c7 li-bullet-0">
          <span className="c2">Request access to Your Personal Data</span>
          <span className="c1">
            . The right to access, update or delete the information We have on
            You. Whenever made possible, you can access, update or request
            deletion of Your Personal Data directly within Your account settings
            section. If you are unable to perform these actions yourself, please
            contact Us to assist You. This also enables You to receive a copy of
            the Personal Data We hold about You.
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c2">
            Request correction of the Personal Data that We hold about You
          </span>
          <span className="c1">
            . You have the right to have any incomplete or inaccurate
            information We hold about You corrected.
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c2">Object to processing of Your Personal Data</span>
          <span className="c1">
            . This right exists where We are relying on a legitimate interest as
            the legal basis for Our processing and there is something about Your
            particular situation, which makes You want to object to our
            processing of Your Personal Data on this ground. You also have the
            right to object where We are processing Your Personal Data for
            direct marketing purposes.
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c2">Request erasure of Your Personal Data</span>
          <span className="c1">
            . You have the right to ask Us to delete or remove Personal Data
            when there is no good reason for Us to continue processing it.
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c2">Request the transfer of Your Personal Data</span>
          <span className="c1">
            . We will provide to You, or to a third-party You have chosen, Your
            Personal Data in a structured, commonly used, machine-readable
            format. Please note that this right only applies to automated
            information which You initially provided consent for Us to use or
            where We used the information to perform a contract with You.
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c2">Withdraw Your consent.</span>
          <span className="c1">
            &nbsp;You have the right to withdraw Your consent on using your
            Personal Data. If You withdraw Your consent, We may not be able to
            provide You with access to certain specific functionalities of the
            Service.
          </span>
        </li>
      </ul>
      <h3 className="c15" id="h.wgcz9bosa0b9">
        <span className="c5">
          Exercising of Your GDPR Data Protection Rights
        </span>
      </h3>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          You may exercise Your rights of access, rectification, cancellation
          and opposition by contacting Us. Please note that we may ask You to
          verify Your identity before responding to such requests. If You make a
          request, We will try our best to respond to You as soon as possible.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          You have the right to complain to a Data Protection Authority about
          Our collection and use of Your Personal Data. For more information, if
          You are in the European Economic Area (EEA), please contact Your local
          data protection authority in the EEA.
        </span>
      </p>
      <h2 className="c3" id="h.wnppdk9hm79">
        <span className="c13">CCPA Privacy Policy</span>
      </h2>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          This privacy notice section for California residents supplements the
          information contained in Our Privacy Policy and it applies solely to
          all visitors, users, and others who reside in the State of California.
        </span>
      </p>
      <h3 className="c15" id="h.fnv60qtl1s0d">
        <span className="c5">Categories of Personal Information Collected</span>
      </h3>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          We collect information that identifies, relates to, describes,
          references, is capable of being associated with, or could reasonably
          be linked, directly or indirectly, with a particular Consumer or
          Device. The following is a list of categories of personal information
          which we may collect or may have been collected from California
          residents within the last twelve (12) months.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          Please note that the categories and examples provided in the list
          below are those defined in the CCPA. This does not mean that all
          examples of that category of personal information were in fact
          collected by Us, but reflects our good faith belief to the best of our
          knowledge that some of that information from the applicable category
          may be and may have been collected. For example, certain categories of
          personal information would only be collected if You provided such
          personal information directly to Us.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_rzpeialmzfx6-0 start">
        <li className="c4 c7 li-bullet-0">
          <span className="c2">
            Category A: Identifiers.
            <br />
            <br />
          </span>
          <span className="c1">
            Examples: A real name, alias, postal address, unique personal
            identifier, online identifier, Internet Protocol address, email
            address, account name, driver&#39;s license number, passport number,
            or other similar identifiers.
            <br />
            <br />
            Collected: Yes.
          </span>
        </li>
      </ul>
      <p className="c0 c12">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_rzpeialmzfx6-0">
        <li className="c4 c7 li-bullet-0">
          <span className="c2">
            Category B: Personal information categories listed in the California
            Customer Records statute (Cal. Civ. Code &sect; 1798.80(e)).
            <br />
            <br />
          </span>
          <span className="c1">
            Examples: A name, signature, Social Security number, physical
            characteristics or description, address, telephone number, passport
            number, driver&#39;s license or state identification card number,
            insurance policy number, education, employment, employment history,
            bank account number, credit card number, debit card number, or any
            other financial information, medical information, or health
            insurance information. Some personal information included in this
            category may overlap with other categories.
            <br />
            <br />
            Collected: Yes.
          </span>
        </li>
      </ul>
      <p className="c0 c12">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_rzpeialmzfx6-0">
        <li className="c4 c7 li-bullet-0">
          <span className="c2">
            Category C: Protected classification characteristics under
            California or federal law.
            <br />
            <br />
          </span>
          <span className="c1">
            Examples: Age (40 years or older), race, color, ancestry, national
            origin, citizenship, religion or creed, marital status, medical
            condition, physical or mental disability, sex (including gender,
            gender identity, gender expression, pregnancy or childbirth and
            related medical conditions), sexual orientation, veteran or military
            status, genetic information (including familial genetic
            information).
            <br />
            <br />
            Collected: No.
          </span>
        </li>
      </ul>
      <p className="c0 c12">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_rzpeialmzfx6-0">
        <li className="c4 c7 li-bullet-0">
          <span className="c2">
            Category D: Commercial information.
            <br />
            <br />
          </span>
          <span className="c1">
            Examples: Records and history of products or services purchased or
            considered.
            <br />
            <br />
            Collected: Yes.
          </span>
        </li>
      </ul>
      <p className="c0 c12">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_rzpeialmzfx6-0">
        <li className="c4 c7 li-bullet-0">
          <span className="c2">
            Category E: Biometric information.
            <br />
            <br />
          </span>
          <span className="c1">
            Examples: Genetic, physiological, behavioral, and biological
            characteristics, or activity patterns used to extract a template or
            other identifier or identifying information, such as, fingerprints,
            faceprints, and voiceprints, iris or retina scans, keystroke, gait,
            or other physical patterns, and sleep, health, or exercise data.
            <br />
            <br />
            Collected: No.
          </span>
        </li>
      </ul>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_rzpeialmzfx6-0">
        <li className="c4 c7 li-bullet-0">
          <span className="c2">
            Category F: Internet or other similar network activity.
            <br />
            <br />
          </span>
          <span className="c1">
            Examples: Interaction with our Service or advertisement.
            <br />
            <br />
            Collected: Yes.
          </span>
        </li>
      </ul>
      <p className="c0 c12">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_rzpeialmzfx6-0">
        <li className="c4 c7 li-bullet-0">
          <span className="c2">
            Category G: Geolocation data.
            <br />
            <br />
          </span>
          <span className="c1">
            Examples: Approximate physical location.
            <br />
            <br />
            Collected: No.
          </span>
        </li>
      </ul>
      <p className="c0 c12">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_rzpeialmzfx6-0">
        <li className="c4 c7 li-bullet-0">
          <span className="c2">
            Category H: Sensory data.
            <br />
            <br />
          </span>
          <span className="c1">
            Examples: Audio, electronic, visual, thermal, olfactory, or similar
            information.
            <br />
            <br />
            Collected: No.
          </span>
        </li>
      </ul>
      <p className="c0 c12">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_rzpeialmzfx6-0">
        <li className="c4 c7 li-bullet-0">
          <span className="c2">
            Category I: Professional or employment-related information.
            <br />
            <br />
          </span>
          <span className="c1">
            Examples: Current or past job history or performance evaluations.
            <br />
            <br />
            Collected: No.
          </span>
        </li>
      </ul>
      <p className="c0 c12">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_rzpeialmzfx6-0">
        <li className="c4 c7 li-bullet-0">
          <span className="c2">
            Category J: Non-public education information (per the Family
            Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34
            C.F.R. Part 99)).
            <br />
            <br />
          </span>
          <span className="c1">
            Examples: Education records directly related to a student maintained
            by an educational institution or party acting on its behalf, such as
            grades, transcripts, class lists, student schedules, student
            identification codes, student financial information, or student
            disciplinary records.
            <br />
            <br />
            Collected: No.
          </span>
        </li>
      </ul>
      <p className="c0 c12">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_rzpeialmzfx6-0">
        <li className="c4 c7 li-bullet-0">
          <span className="c2">
            Category K: Inferences drawn from other personal information.
            <br />
            <br />
          </span>
          <span className="c1">
            Examples: Profile reflecting a person&#39;s preferences,
            characteristics, psychological trends, predispositions, behavior,
            attitudes, intelligence, abilities, and aptitudes.
            <br />
            <br />
            Collected: No.
          </span>
        </li>
      </ul>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          Under CCPA, personal information does not include:
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_xz69zvgaa4v9-0 start">
        <li className="c4 c7 li-bullet-0">
          <span className="c1">
            Publicly available information from government records
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">
            Deidentified or aggregated consumer information
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">
            Information excluded from the CCPA&#39;s scope, such as:
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">
            Health or medical information covered by the Health Insurance
            Portability and Accountability Act of 1996 (HIPAA) and the
            California Confidentiality of Medical Information Act (CMIA) or
            clinical trial data
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">
            Personal Information covered by certain sector-specific privacy
            laws, including the Fair Credit Reporting Act (FRCA), the
            Gramm-Leach-Bliley Act (GLBA) or California Financial Information
            Privacy Act (FIPA), and the Driver&#39;s Privacy Protection Act of
            1994
          </span>
        </li>
      </ul>
      <h3 className="c15" id="h.uy7zwg5i08oq">
        <span className="c5">Sources of Personal Information</span>
      </h3>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          We obtain the categories of personal information listed above from the
          following categories of sources:
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_3tm2bh1t3r32-0 start">
        <li className="c4 c7 li-bullet-0">
          <span className="c1">
            Directly from You. For example, from the forms You complete on our
            Service, preferences You express or provide through our Service, or
            from Your purchases on our Service.
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">
            Indirectly from You. For example, from observing Your activity on
            our Service.
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">
            Automatically from You. For example, through cookies We or our
            Service Providers set on Your Device as You navigate through our
            Service.
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">
            From Service Providers. For example, third-party vendors to monitor
            and analyze the use of our Service, third-party vendors for payment
            processing, or other third-party vendors that We use to provide the
            Service to You.
          </span>
        </li>
      </ul>
      <h3 className="c15" id="h.6rafaww3qje0">
        <span className="c5">
          Use of Personal Information for Business Purposes or Commercial
          Purposes
        </span>
      </h3>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          We may use or disclose personal information We collect for
          &quot;business purposes&quot; or &quot;commercial purposes&quot; (as
          defined under the CCPA), which may include the following examples:
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_3umc7xlufx33-0 start">
        <li className="c4 c7 li-bullet-0">
          <span className="c1">
            To operate our Service and provide You with our Service.
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">
            To provide You with support and to respond to Your inquiries,
            including to investigate and address Your concerns and monitor and
            improve our Service.
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">
            To fulfill or meet the reason You provided the information. For
            example, if You share Your contact information to ask a question
            about our Service, We will use that personal information to respond
            to Your inquiry. If You provide Your personal information to
            purchase a product or service, We will use that information to
            process Your payment and facilitate delivery.
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">
            To respond to law enforcement requests and as required by applicable
            law, court order, or governmental regulations.
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">
            As described to You when collecting Your personal information or as
            otherwise set forth in the CCPA.
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">
            For internal administrative and auditing purposes.
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">
            To detect security incidents and protect against malicious,
            deceptive, fraudulent or illegal activity, including, when
            necessary, to prosecute those responsible for such activities.
          </span>
        </li>
      </ul>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          Please note that the examples provided above are illustrative and not
          intended to be exhaustive. For more details on how we use this
          information, please refer to the &quot;Use of Your Personal Data&quot;
          section.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          If We decide to collect additional categories of personal information
          or use the personal information We collected for materially different,
          unrelated, or incompatible purposes We will update this Privacy
          Policy.
        </span>
      </p>
      <h3 className="c15" id="h.ikl3vh6sau5c">
        <span className="c5">
          Disclosure of Personal Information for Business Purposes or Commercial
          Purposes
        </span>
      </h3>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          We may use or disclose and may have used or disclosed in the last
          twelve (12) months the following categories of personal information
          for business or commercial purposes:
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_lzd33po26f8c-0 start">
        <li className="c4 c7 li-bullet-0">
          <span className="c1">Category A: Identifiers</span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">
            Category B: Personal information categories listed in the California
            Customer Records statute (Cal. Civ. Code &sect; 1798.80(e))
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">Category D: Commercial information</span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">
            Category F: Internet or other similar network activity
          </span>
        </li>
      </ul>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          Please note that the categories listed above are those defined in the
          CCPA. This does not mean that all examples of that category of
          personal information were in fact disclosed, but reflects our good
          faith belief to the best of our knowledge that some of that
          information from the applicable category may be and may have been
          disclosed.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          When We disclose personal information for a business purpose or a
          commercial purpose, We enter a contract that describes the purpose and
          requires the recipient to both keep that personal information
          confidential and not use it for any purpose except performing the
          contract.
        </span>
      </p>
      <h3 className="c15" id="h.gb9guecz03n">
        <span className="c5">Sale of Personal Information</span>
      </h3>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          As defined in the CCPA, &quot;sell&quot; and &quot;sale&quot; mean
          selling, renting, releasing, disclosing, disseminating, making
          available, transferring, or otherwise communicating orally, in
          writing, or by electronic or other means, a consumer&#39;s personal
          information by the business to a third party for valuable
          consideration. This means that We may have received some kind of
          benefit in return for sharing personal information, but not
          necessarily a monetary benefit.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          Please note that the categories listed below are those defined in the
          CCPA. This does not mean that all examples of that category of
          personal information were in fact sold, but reflects our good faith
          belief to the best of our knowledge that some of that information from
          the applicable category may be and may have been shared for value in
          return.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          We may sell and may have sold in the last twelve (12) months the
          following categories of personal information:
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_jrp0yv1mil1z-0 start">
        <li className="c4 c7 li-bullet-0">
          <span className="c1">Category A: Identifiers</span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">
            Category B: Personal information categories listed in the California
            Customer Records statute (Cal. Civ. Code &sect; 1798.80(e))
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">Category D: Commercial information</span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">
            Category F: Internet or other similar network activity
          </span>
        </li>
      </ul>
      <h3 className="c15" id="h.ewpxkgg5da05">
        <span className="c5">Share of Personal Information</span>
      </h3>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          We may share Your personal information identified in the above
          categories with the following categories of third parties:
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_uaclegj46s9z-0 start">
        <li className="c4 c7 li-bullet-0">
          <span className="c1">Service Providers</span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">Payment processors</span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">Our affiliates</span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">Our business partners</span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">
            Third party vendors to whom You or Your agents authorize Us to
            disclose Your personal information in connection with products or
            services We provide to You
          </span>
        </li>
      </ul>
      <h3 className="c15" id="h.smx4xv292brh">
        <span className="c5">
          Sale of Personal Information of Minors Under 16 Years of Age
        </span>
      </h3>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          We do not knowingly collect personal information from minors under the
          age of 16 through our Service, although certain third party websites
          that we link to may do so. These third-party websites have their own
          terms of use and privacy policies and we encourage parents and legal
          guardians to monitor their children&#39;s Internet usage and instruct
          their children to never provide information on other websites without
          their permission.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          We do not sell the personal information of Consumers We actually know
          are less than 16 years of age, unless We receive affirmative
          authorization (the &quot;right to opt-in&quot;) from either the
          Consumer who is between 13 and 16 years of age, or the parent or
          guardian of a Consumer less than 13 years of age. Consumers who opt-in
          to the sale of personal information may opt-out of future sales at any
          time. To exercise the right to opt-out, You (or Your authorized
          representative) may submit a request to Us by contacting Us.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          If You have reason to believe that a child under the age of 13 (or 16)
          has provided Us with personal information, please contact Us with
          sufficient detail to enable Us to delete that information.
        </span>
      </p>
      <h3 className="c15" id="h.5p3fo09xqhzq">
        <span className="c5">Your Rights under the CCPA</span>
      </h3>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          The CCPA provides California residents with specific rights regarding
          their personal information. If You are a resident of California, You
          have the following rights:
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_u1xskgsnqjeg-0 start">
        <li className="c4 c7 li-bullet-0">
          <span className="c2">The right to notice</span>
          <span className="c1">
            . You have the right to be notified which categories of Personal
            Data are being collected and the purposes for which the Personal
            Data is being used.
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c2">The right to request.</span>
          <span className="c1">
            &nbsp;Under CCPA, You have the right to request that We disclose
            information to You about Our collection, use, sale, disclosure for
            business purposes and share of personal information. Once We receive
            and confirm Your request, We will disclose to You:
          </span>
        </li>
      </ul>
      <ul className="c9 lst-kix_u1xskgsnqjeg-1 start">
        <li className="c4 c11 li-bullet-0">
          <span className="c1">
            The categories of personal information We collected about You
          </span>
        </li>
        <li className="c4 c11 li-bullet-0">
          <span className="c1">
            The categories of sources for the personal information We collected
            about You
          </span>
        </li>
        <li className="c4 c11 li-bullet-0">
          <span className="c1">
            Our business or commercial purpose for collecting or selling that
            personal information
          </span>
        </li>
        <li className="c4 c11 li-bullet-0">
          <span className="c1">
            The categories of third parties with whom We share that personal
            information
          </span>
        </li>
        <li className="c4 c11 li-bullet-0">
          <span className="c1">
            The specific pieces of personal information We collected about You
          </span>
        </li>
        <li className="c4 c11 li-bullet-0">
          <span className="c1">
            If we sold Your personal information or disclosed Your personal
            information for a business purpose, We will disclose to You:
          </span>
        </li>
      </ul>
      <ul className="c9 lst-kix_u1xskgsnqjeg-2 start">
        <li className="c4 c19 li-bullet-0">
          <span className="c1">
            The categories of personal information categories sold
          </span>
        </li>
        <li className="c4 c19 li-bullet-0">
          <span className="c1">
            The categories of personal information categories disclosed
          </span>
        </li>
      </ul>
      <ul className="c9 lst-kix_u1xskgsnqjeg-0">
        <li className="c4 c7 li-bullet-0">
          <span className="c2">
            The right to say no to the sale of Personal Data (opt-out).
          </span>
          <span className="c1">
            &nbsp;You have the right to direct Us to not sell Your personal
            information. To submit an opt-out request please contact Us.
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c2">The right to delete Personal Data.</span>
          <span className="c1">
            &nbsp;You have the right to request the deletion of Your Personal
            Data, subject to certain exceptions. Once We receive and confirm
            Your request, We will delete (and direct Our Service Providers to
            delete) Your personal information from our records, unless an
            exception applies. We may deny Your deletion request if retaining
            the information is necessary for Us or Our Service Providers to:
          </span>
        </li>
      </ul>
      <ul className="c9 lst-kix_u1xskgsnqjeg-1 start">
        <li className="c4 c11 li-bullet-0">
          <span className="c1">
            Complete the transaction for which We collected the personal
            information, provide a good or service that You requested, take
            actions reasonably anticipated within the context of our ongoing
            business relationship with You, or otherwise perform our contract
            with You.
          </span>
        </li>
        <li className="c4 c11 li-bullet-0">
          <span className="c1">
            Detect security incidents, protect against malicious, deceptive,
            fraudulent, or illegal activity, or prosecute those responsible for
            such activities.
          </span>
        </li>
        <li className="c4 c11 li-bullet-0">
          <span className="c1">
            Debug products to identify and repair errors that impair existing
            intended functionality.
          </span>
        </li>
        <li className="c4 c11 li-bullet-0">
          <span className="c1">
            Exercise free speech, ensure the right of another consumer to
            exercise their free speech rights, or exercise another right
            provided for by law.
          </span>
        </li>
        <li className="c4 c11 li-bullet-0">
          <span className="c1">
            Comply with the California Electronic Communications Privacy Act
            (Cal. Penal Code &sect; 1546 et. seq.).
          </span>
        </li>
        <li className="c4 c11 li-bullet-0">
          <span className="c1">
            Engage in public or peer-reviewed scientific, historical, or
            statistical research in the public interest that adheres to all
            other applicable ethics and privacy laws, when the information&#39;s
            deletion may likely render impossible or seriously impair the
            research&#39;s achievement, if You previously provided informed
            consent.
          </span>
        </li>
        <li className="c4 c11 li-bullet-0">
          <span className="c1">
            Enable solely internal uses that are reasonably aligned with
            consumer expectations based on Your relationship with Us.
          </span>
        </li>
        <li className="c4 c11 li-bullet-0">
          <span className="c1">Comply with a legal obligation.</span>
        </li>
        <li className="c4 c11 li-bullet-0">
          <span className="c1">
            Make other internal and lawful uses of that information that are
            compatible with the context in which You provided it.
          </span>
        </li>
      </ul>
      <ul className="c9 lst-kix_u1xskgsnqjeg-0">
        <li className="c4 c7 li-bullet-0">
          <span className="c2">The right not to be discriminated against.</span>
          <span className="c1">
            &nbsp;You have the right not to be discriminated against for
            exercising any of Your consumer&#39;s rights, including by:
          </span>
        </li>
      </ul>
      <ul className="c9 lst-kix_u1xskgsnqjeg-1 start">
        <li className="c4 c11 li-bullet-0">
          <span className="c1">Denying goods or services to You</span>
        </li>
        <li className="c4 c11 li-bullet-0">
          <span className="c1">
            Charging different prices or rates for goods or services, including
            the use of discounts or other benefits or imposing penalties
          </span>
        </li>
        <li className="c4 c11 li-bullet-0">
          <span className="c1">
            Providing a different level or quality of goods or services to You
          </span>
        </li>
        <li className="c4 c11 li-bullet-0">
          <span className="c1">
            Suggesting that You will receive a different price or rate for goods
            or services or a different level or quality of goods or services
          </span>
        </li>
      </ul>
      <h3 className="c15" id="h.an4yuheptsi5">
        <span className="c5">Exercising Your CCPA Data Protection Rights</span>
      </h3>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          In order to exercise any of Your rights under the CCPA, and if You are
          a California resident, You can contact us at coverbot [at] microweb
          [dot] ltd.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          Only You, or a person registered with the California Secretary of
          State that You authorize to act on Your behalf, may make a verifiable
          request related to Your personal information.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">Your request to Us must:</span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_4sfjr15g64q6-0 start">
        <li className="c4 c7 li-bullet-0">
          <span className="c1">
            Provide sufficient information that allows Us to reasonably verify
            You are the person about whom We collected personal information or
            an authorized representative
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">
            Describe Your request with sufficient detail that allows Us to
            properly understand, evaluate, and respond to it
          </span>
        </li>
      </ul>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          We cannot respond to Your request or provide You with the required
          information if we cannot:
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_v3jyuzh8lbvc-0 start">
        <li className="c4 c7 li-bullet-0">
          <span className="c1">
            Verify Your identity or authority to make the request
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">
            And confirm that the personal information relates to You
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">
            We will disclose and deliver the required information free of charge
            within 45 days of receiving Your verifiable request. The time period
            to provide the required information may be extended once by an
            additional 45 days when reasonably necessary and with prior notice.
          </span>
        </li>
      </ul>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          Any disclosures We provide will only cover the 12-month period
          preceding the verifiable request&#39;s receipt.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          For data portability requests, We will select a format to provide Your
          personal information that is readily usable and should allow You to
          transmit the information from one entity to another entity without
          hindrance.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <h3 className="c15" id="h.rzmvo7a401u1">
        <span className="c5">Do Not Sell My Personal Information</span>
      </h3>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          You have the right to opt-out of the sale of Your personal
          information. Once We receive and confirm a verifiable consumer request
          from You, we will stop selling Your personal information. To exercise
          Your right to opt-out, please contact Us.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          The Service Providers we partner with (for example, our analytics or
          advertising partners) may use technology on the Service that sells
          personal information as defined by the CCPA law. If you wish to opt
          out of the use of Your personal information for interest-based
          advertising purposes and these potential sales as defined under CCPA
          law, you may do so by following the instructions below.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          Please note that any opt out is specific to the browser You use. You
          may need to opt out on every browser that You use.
        </span>
      </p>
      <h4 className="c8" id="h.4pmpviqtsnpo">
        <span className="c23">Website</span>
      </h4>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          You can opt out of receiving ads that are personalized as served by
          our Service Providers by following our instructions presented on the
          Service:
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_yz60afi3uzyw-0 start">
        <li className="c4 c7 li-bullet-0">
          <span>The NAI&#39;s opt-out platform: </span>
          <span className="c26">
            <a
              className="c10"
              href="https://www.google.com/url?q=http://www.networkadvertising.org/choices/&amp;sa=D&amp;source=editors&amp;ust=1691231639469153&amp;usg=AOvVaw1cDWPf9mZZRH072xuKkH-E"
            >
              http://www.networkadvertising.org/choices/
            </a>
          </span>
          <span className="c1">&nbsp;</span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span>The EDAA&#39;s opt-out platform </span>
          <span className="c26">
            <a
              className="c10"
              href="https://www.google.com/url?q=http://www.youronlinechoices.com/&amp;sa=D&amp;source=editors&amp;ust=1691231639469404&amp;usg=AOvVaw2n-os2JchRjs-UbZtxWOUN"
            >
              http://www.youronlinechoices.com/
            </a>
          </span>
          <span className="c1">&nbsp;</span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span>The DAA&#39;s opt-out platform: </span>
          <span className="c26">
            <a
              className="c10"
              href="https://www.google.com/url?q=http://optout.aboutads.info/?c%3D2%26lang%3DEN&amp;sa=D&amp;source=editors&amp;ust=1691231639469636&amp;usg=AOvVaw3h0mVf_RKBC_2uJjFRIaMA"
            >
              http://optout.aboutads.info/?c=2&amp;lang=EN
            </a>
          </span>
          <span className="c1">&nbsp;</span>
        </li>
      </ul>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          The opt out will place a cookie on Your computer that is unique to the
          browser You use to opt out. If you change browsers or delete the
          cookies saved by your browser, You will need to opt out again.
        </span>
      </p>
      <h4 className="c8" id="h.3cirdysi80fy">
        <span className="c23">Mobile Devices</span>
      </h4>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          Your mobile device may give You the ability to opt out of the use of
          information about the apps You use in order to serve You ads that are
          targeted to Your interests:
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <ul className="c9 lst-kix_q8w6usbtdw2v-0 start">
        <li className="c4 c7 li-bullet-0">
          <span className="c1">
            &quot;Opt out of Interest-Based Ads&quot; or &quot;Opt out of Ads
            Personalization&quot; on Android devices
          </span>
        </li>
        <li className="c4 c7 li-bullet-0">
          <span className="c1">
            &quot;Limit Ad Tracking&quot; on iOS devices
          </span>
        </li>
      </ul>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          You can also stop the collection of location information from Your
          mobile device by changing the preferences on Your mobile device.
        </span>
      </p>
      <h2 className="c3" id="h.agi5bxz4xss6">
        <span className="c13">
          &quot;Do Not Track&quot; Policy as Required by California Online
          Privacy Protection Act (CalOPPA)
        </span>
      </h2>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          Our Service does not respond to Do Not Track signals.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          However, some third party websites do keep track of Your browsing
          activities. If You are visiting such websites, You can set Your
          preferences in Your web browser to inform websites that You do not
          want to be tracked. You can enable or disable DNT by visiting the
          preferences or settings page of Your web browser.
        </span>
      </p>
      <h2 className="c3" id="h.c99l3aqagbay">
        <span className="c13">Children&#39;s Privacy</span>
      </h2>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          Our Service does not address anyone under the age of 13. We do not
          knowingly collect personally identifiable information from anyone
          under the age of 13. If You are a parent or guardian and You are aware
          that Your child has provided Us with Personal Data, please contact Us.
          If We become aware that We have collected Personal Data from anyone
          under the age of 13 without verification of parental consent, We take
          steps to remove that information from Our servers.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          If We need to rely on consent as a legal basis for processing Your
          information and Your country requires consent from a parent, We may
          require Your parent&#39;s consent before We collect and use that
          information.
        </span>
      </p>
      <h2 className="c3" id="h.kn8bfu2rzvxo">
        <span className="c13">
          Your California Privacy Rights (California&#39;s Shine the Light law)
        </span>
      </h2>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          Under California Civil Code Section 1798 (California&#39;s Shine the
          Light law), California residents with an established business
          relationship with us can request information once a year about sharing
          their Personal Data with third parties for the third parties&#39;
          direct marketing purposes.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          If you&#39;d like to request more information under the California
          Shine the Light law, and if You are a California resident, You can
          contact Us using the contact information provided below.
        </span>
      </p>
      <h2 className="c3" id="h.z4jj5h714gz6">
        <span className="c13">
          California Privacy Rights for Minor Users (California Business and
          Professions Code Section 22581)
        </span>
      </h2>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          California Business and Professions Code Section 22581 allows
          California residents under the age of 18 who are registered users of
          online sites, services or applications to request and obtain removal
          of content or information they have publicly posted.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          To request removal of such data, and if You are a California resident,
          You can contact Us using the contact information provided below, and
          include the email address associated with Your account.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          Be aware that Your request does not guarantee complete or
          comprehensive removal of content or information posted online and that
          the law may not permit or require removal in certain circumstances.
        </span>
      </p>
      <h2 className="c3" id="h.ecxybpwpl2n2">
        <span className="c13">Links to Other Websites</span>
      </h2>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          Our Service may contain links to other websites that are not operated
          by Us. If You click on a third party link, You will be directed to
          that third party&#39;s site. We strongly advise You to review the
          Privacy Policy of every site You visit.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </span>
      </p>
      <h2 className="c3" id="h.s1qgjyorhhh7">
        <span className="c13">Changes to this Privacy Policy</span>
      </h2>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          We may update Our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          We will let You know via email and/or a prominent notice on Our
          Service, prior to the change becoming effective and update the
          &quot;Last updated&quot; date at the top of this Privacy Policy.
        </span>
      </p>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </span>
      </p>
      <h2 className="c3" id="h.rhij46lryv2t">
        <span className="c13">Contact Us</span>
      </h2>
      <p className="c0">
        <span className="c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          If you have any questions about this Privacy Policy, You can contact
          us at coverbot [at] microweb [dot] ltd.
        </span>
      </p>
    </div>
  );
}
