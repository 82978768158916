// React
import { useEffect } from 'react';

// Router
import { useParams, useNavigate } from 'react-router-dom';

export default function Auth() {
  const { x } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!x) return;

    localStorage.setItem('U532D474', x);

    navigate('/dashboard');
  }, [navigate, x]);

  return <></>;
}
