// React
import { ReactNode } from 'react';

// Styles
import './ModalX.scss';

import Modal from 'react-modal';

interface ModalXProps {
  open: boolean;
  children?: ReactNode;
}

export default function ModalX({ open, children }: ModalXProps) {
  return (
    <Modal ariaHideApp={false} closeTimeoutMS={150} isOpen={open}>
      <div className="ReactModal__Box">{children}</div>
    </Modal>
  );
}
