import { useRef } from 'react';
import ModalX from '../ModalX/ModalX';
import './AlertDialog.scss';
import { useNavigate } from 'react-router-dom';

export function SignInToBegin({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const loginRef = useRef(null);
  const navigate = useNavigate();

  return (
    <ModalX open={isOpen}>
      <div className="modal__content">
        <h1>Sign in to Begin</h1>
        <div className="modal__text">
          <p style={{ margin: '1rem 0 0.5rem 0' }}>
            Please sign in first to get started!
          </p>
        </div>

        <div className="modal__buttons">
          <button onClick={onClose}>Cancel</button>
          <button ref={loginRef} onClick={() => navigate('/signin')}>
            Sign In
          </button>
        </div>
      </div>
    </ModalX>
  );
}

export function DeleteCoverLetter({
  isOpen,
  onClose,
  handleConfirm,
}: {
  isOpen: boolean;
  onClose: () => void;
  handleConfirm: () => void;
}) {
  return (
    <ModalX open={isOpen}>
      <div className="modal__content">
        <h1>Delete Cover Letter</h1>

        <div className="modal__text">
          <p>Are you sure you want to delete this cover letter?</p>
          <p>
            If you delete this cover letter, you will not be able to recover it.
          </p>
        </div>

        <div className="modal__buttons">
          <button onClick={onClose}>Cancel</button>
          <button onClick={handleConfirm}>Delete</button>
        </div>
      </div>
    </ModalX>
  );
}

export function OutOfCredits({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const navigate = useNavigate();

  return (
    <ModalX open={isOpen}>
      <div className="modal__content">
        <h1>You Are Out Of Cover Letters</h1>
        <div className="modal__text">
          <p style={{ margin: '1rem 0 0.5rem 0' }}>
            Please head over to your account page to purchase more cover
            letters.
          </p>
        </div>

        <div className="modal__buttons">
          <button onClick={onClose}>Cancel</button>
          <button onClick={() => navigate('/account')}>Buy Now</button>
        </div>
      </div>
    </ModalX>
  );
}

export function GenericError({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  return (
    <ModalX open={isOpen}>
      <div className="modal__content">
        <h1>Server Error</h1>
        <div className="modal__text">
          <p style={{ margin: '1rem 0 0.5rem 0' }}>
            Oops! Something went wrong. Please try again later.
          </p>
        </div>

        <div className="modal__buttons">
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </ModalX>
  );
}
