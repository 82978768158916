import './HelpPage.scss';
import { useUser } from '../../hooks/useUserData';

export default function HelpPage() {
  const { userData } = useUser();

  return (
    <div className="help">
      <h3>Help</h3>
      <p>
        If you have any questions about the web app, please check the list of
        FAQs below
        {userData ? (
          <span>
            {' '}
            or email us at{' '}
            <a href="mailto:coverbot@microweb.ltd">coverbot@microweb.ltd</a>.
          </span>
        ) : (
          '.'
        )}
      </p>
      <details>
        <summary>What is this web app?</summary>
        <p>
          This web app is a tool that helps job seekers generate personalised
          covering letters for job applications.
        </p>
      </details>
      <details>
        <summary>How does the web app work?</summary>
        <p>
          The web app uses natural language processing (NLP) algorithms to
          analyse the keywords and phrases in your CV and the job description.
          It then uses this information to generate a customised cover letter
          that highlights your relevant skills and experience.
        </p>
      </details>
      <details>
        <summary>What do I need to use the web app?</summary>
        <p>
          To use the web app, you will need a CV in PDF format, a job
          description and role details for the job you are applying for.
        </p>
      </details>
      <details>
        <summary>
          How accurate are the cover letters generated by the web app?
        </summary>
        <p>
          The accuracy of the cover letters generated by the web app depends on
          the quality of the CV and job description you provide. The more
          relevant information you include, the better the cover letter will be.
          However, the web app is designed to provide a starting point for your
          cover letter, and you should always review and customise the letter to
          fit your needs.
        </p>
      </details>
      <details>
        <summary>Is my personal information secure?</summary>
        <p>
          Yes, your personal information is secure. The web app uses encryption
          to protect your data, and we do not share your information with any
          third parties.
        </p>
      </details>
      <details>
        <summary>What languages does the web app support?</summary>
        <p>
          The web app currently supports English, but we are working on adding
          support for German and French in the near future.
        </p>
      </details>
      <details>
        <summary>How much does it cost to use the web app?</summary>
        <p>
          New users are entitled to three free cover letters. Additional cover
          letters may be purchased at a nominal fee.
        </p>
      </details>
      <details>
        <summary>Can I use the web app on my mobile device?</summary>
        <p>
          Yes, the web app is designed to work on mobile devices as well as
          desktop computers via a web browser.
        </p>
      </details>
    </div>
  );
}
