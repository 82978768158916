import { useRef, useEffect, useState } from 'react';
import ModalX from '../ModalX/ModalX';
import './CoverLetterView.scss';

type ModalProps = {
  coverLetterData: string;
  isOpen: boolean;
  onClose: () => void;
};

export default function ModalElement({
  coverLetterData,
  isOpen,
  onClose,
}: ModalProps) {
  const [selectedCoverLetter, setSelectedCoverLetter] = useState<string>(' ');
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const onCopy = () => {
    textAreaRef.current?.select();
    document.execCommand('copy');
    alert('Copied to clipboard!');
  };

  useEffect(() => {
    setSelectedCoverLetter(coverLetterData);
  }, [coverLetterData]);

  return (
    <ModalX open={isOpen}>
      <div className="modal__content">
        <h1>Your Cover Letter</h1>

        <div className="modal__textarea">
          <textarea readOnly ref={textAreaRef} value={selectedCoverLetter} />
        </div>

        <div className="modal__buttons">
          <button onClick={onClose}>Close</button>
          <button onClick={onCopy}>Copy</button>
        </div>
      </div>
    </ModalX>
  );
}
