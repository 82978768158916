import { Link, useNavigate } from 'react-router-dom';
import './AccountPage.scss';
import { useUser } from '../../hooks/useUserData';
import { useState, useEffect } from 'react';
import Spinner from '../../components/Spinner/Spinner';
import { GenericError } from '../../components/AlertDialog/AlertDialog';

interface IAccountData {
  email: string;
  credits: number;
  lifeTimeAccess: boolean;
}

export default function AccountPage() {
  const { userData, logOut } = useUser();
  const [genericError, setGenericError] = useState<boolean>(false);
  const [accountData, setAccountData] = useState<IAccountData>({
    email: '',
    credits: 0,
    lifeTimeAccess: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!userData) return;

    if (!localStorage.getItem('U532D474')) {
      navigate('/signin');

      return;
    }

    (async () => {
      fetch(`${process.env.REACT_APP_SERVER_URL}/account`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userData?.split(':')[0].substring(1)}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success === 'ok') {
            setAccountData(data.accountInfo);

            return;
          } else {
            console.log(data);
            navigate('/signin/e');
          }
        })
        .catch(() => {
          setGenericError(true);
        });
    })();
  }, [userData, logOut, navigate]);

  const handleBuy = (quantity: number | string) => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/buy/${quantity}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userData?.split(':')[0].substring(1)}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        window.open(data.sessionUrl, '_self');
      })
      .catch(() => {
        setGenericError(true);
      });
  };

  return (
    <div className="profile">
      <h3>Account</h3>
      {userData && accountData?.email ? (
        <div className="profile__container">
          <div className="profile__general">
            <h4>General Information</h4>
            <p>
              Logged in as: <strong>{accountData?.email}</strong>
            </p>

            {accountData?.lifeTimeAccess ? (
              <>
                <p style={{ color: 'green' }}>
                  <strong>You have LIFETIME ACCESS to CoverBot!</strong>
                </p>

                <h4>Support</h4>
                <p>
                  If you have any questions or concerns, please head over to our{' '}
                  <Link to="/help">help page</Link> for more information.
                </p>
              </>
            ) : (
              <p>
                Covering letters left: <strong>{accountData?.credits}</strong>
              </p>
            )}
          </div>

          {!accountData?.lifeTimeAccess && (
            <div className="profile__cards">
              <h4>Buy Covering Letters</h4>
              <p>
                Limited time offer. Buy covering letters at a discounted price.
              </p>

              <div className="profile__buy">
                <div className="profile__buy-container">
                  <p>
                    LIFETIME ACCESS
                    <span className="profile__buy-dash">&nbsp;—&nbsp;</span>
                  </p>
                  <span style={{ color: 'red' }}>
                    <s>$99.99</s>
                    &nbsp;
                  </span>
                  <span>$49.99</span>
                </div>
                <button
                  className="profile__best-buy"
                  onClick={() => handleBuy('LIFETIME')}
                >
                  Buy Now
                </button>
              </div>

              <div className="profile__buy">
                <div className="profile__buy-container">
                  <p>
                    50 Cover Letters
                    <span className="profile__buy-dash">&nbsp;—&nbsp;</span>
                  </p>
                  <span>$14.99</span>
                </div>
                <button onClick={() => handleBuy(50)}>Buy Now</button>
              </div>

              <div className="profile__buy">
                <div className="profile__buy-container">
                  <p>
                    25 Cover Letters
                    <span className="profile__buy-dash">&nbsp;—&nbsp;</span>
                  </p>
                  <span>$9.99</span>
                </div>
                <button onClick={() => handleBuy(25)}>Buy Now</button>
              </div>

              <div className="profile__buy">
                <div className="profile__buy-container">
                  <p>
                    10 Cover Letters
                    <span className="profile__buy-dash">&nbsp;—&nbsp;</span>
                  </p>
                  <span>$4.99</span>
                </div>
                <button onClick={() => handleBuy(10)}>Buy Now</button>
              </div>

              <h4>Support</h4>
              <p>
                If you have any questions or concerns, please head over to our{' '}
                <Link to="/help">help page</Link> for more information or feel
                free to email us at{' '}
                <a href="mailto:coverbot@microweb.ltd">coverbot@microweb.ltd</a>
                .
              </p>
            </div>
          )}

          <button className="profile__signout" onClick={logOut}>
            Sign Out
          </button>
        </div>
      ) : (
        <Spinner />
      )}

      {genericError && (
        <GenericError
          isOpen={Boolean(genericError)}
          onClose={() => setGenericError(false)}
        />
      )}
    </div>
  );
}
