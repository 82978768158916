import './Terms.scss';

import { useEffect } from 'react';

export default function Terms() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="terms">
      <h1 className="c14" id="h.fznxgpf623a1">
        <span className="c15">Terms and Conditions</span>
      </h1>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span>Our Terms and Conditions were last updated on </span>
        <span className="c10">1st August 2023</span>
        <span className="c1">.</span>
      </p>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          Please read these terms and conditions carefully before using Our
          Service.
        </span>
      </p>
      <h2 className="c9" id="h.7qvw69opv781">
        <span className="c6">Interpretation and Definitions</span>
      </h2>
      <h3 className="c5" id="h.e3ehj3mv2fci">
        <span className="c2">Interpretation</span>
      </h3>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          The words of which the initial letter is capitalised have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </span>
      </p>
      <h3 className="c5" id="h.q36aaqojm8hj">
        <span className="c2">Definitions</span>
      </h3>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          For the purposes of these Terms and Conditions:
        </span>
      </p>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <ul className="c12 lst-kix_yurljt73rzub-0 start">
        <li className="c0 li-bullet-0">
          <span>&ldquo;</span>
          <span className="c11">Affiliate</span>
          <span className="c1">
            &rdquo; means an entity that controls, is controlled by or is under
            common control with a party, where &quot;control&quot; means
            ownership of 50% or more of the shares, equity interest or other
            securities entitled to vote for election of directors or other
            managing authority.
          </span>
        </li>
        <li className="c0 li-bullet-0">
          <span>&ldquo;</span>
          <span className="c11">Account</span>
          <span className="c1">
            &rdquo; means a unique account created for You to access our Service
            or parts of our Service.
          </span>
        </li>
        <li className="c0 li-bullet-0">
          <span>&ldquo;</span>
          <span className="c11">Company</span>
          <span>
            &rdquo; (referred to as either &quot;the Company&quot;,
            &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement)
            refers to{' '}
          </span>
          <span className="c10">MicroWeb</span>
          <span className="c1">.</span>
        </li>
        <li className="c0 li-bullet-0">
          <span>&ldquo;</span>
          <span className="c11">Country</span>
          <span>&rdquo; refers to </span>
          <span className="c10">
            the United Kingdom of Great Britain and Northern Ireland
          </span>
          <span className="c1">.</span>
        </li>
        <li className="c0 li-bullet-0">
          <span>&ldquo;</span>
          <span className="c11">Content</span>
          <span className="c1">
            &rdquo; refers to content such as text, images, or other information
            that can be posted, uploaded, linked to or otherwise made available
            by You, regardless of the form of that content.
          </span>
        </li>
        <li className="c0 li-bullet-0">
          <span>&ldquo;</span>
          <span className="c11">Device</span>
          <span className="c1">
            &rdquo; means any device that can access the Service such as a
            computer, a cell phone or a digital tablet.
          </span>
        </li>
        <li className="c0 li-bullet-0">
          <span>&ldquo;</span>
          <span className="c11">Feedback</span>
          <span className="c1">
            &rdquo; means feedback, innovations or suggestions sent by You
            regarding the attributes, performance or features of our Service.
          </span>
        </li>
        <li className="c0 li-bullet-0">
          <span>&ldquo;</span>
          <span className="c11">Service</span>
          <span className="c1">&rdquo; refers to the Website.</span>
        </li>
        <li className="c0 li-bullet-0">
          <span>&ldquo;</span>
          <span className="c11">Terms and Conditions</span>
          <span>&rdquo; (also referred as &quot;</span>
          <span className="c11">Terms</span>
          <span>
            &quot;) mean these Terms and Conditions that form the entire
            agreement between You and the Company regarding the use of the
            Service.
          </span>
        </li>
        <li className="c0 li-bullet-0">
          <span>&ldquo;</span>
          <span className="c11">Third-party Social Media Service</span>
          <span className="c1">
            &rdquo; means any services or content (including data, information,
            products or services) provided by a third-party that may be
            displayed, included or made available by the Service.
          </span>
        </li>
        <li className="c0 li-bullet-0">
          <span>&ldquo;</span>
          <span className="c11">Website</span>
          <span>&rdquo; refers to </span>
          <span className="c10">CoverBot</span>
          <span>, accessible from</span>
          <span className="c1 c10">&nbsp;www.coverbot.net</span>
        </li>
        <li className="c0 li-bullet-0">
          <span>&ldquo;</span>
          <span className="c11">You</span>
          <span className="c1">
            &rdquo; means the individual accessing or using the Service, or the
            company, or other legal entity on behalf of which such individual is
            accessing or using the Service, as applicable.
          </span>
        </li>
      </ul>
      <h2 className="c9" id="h.4mmkzg93e4x1">
        <span className="c6">Acknowledgment</span>
      </h2>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          These are the Terms and Conditions governing the use of this Service
          and the agreement that operates between You and the Company. These
          Terms and Conditions set out the rights and obligations of all users
          regarding the use of the Service.
        </span>
      </p>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          Your access to and use of the Service is conditioned on Your
          acceptance of and compliance with these Terms and Conditions. These
          Terms and Conditions apply to all visitors, users and others who
          access or use the Service.
        </span>
      </p>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          By accessing or using the Service You agree to be bound by these Terms
          and Conditions. If You disagree with any part of these Terms and
          Conditions then You may not access the Service.
        </span>
      </p>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          You represent that you are over the age of 18. The Company does not
          permit those under 18 to use the Service.
        </span>
      </p>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          Your access to and use of the Service is also conditioned on Your
          acceptance of and compliance with the Privacy Policy of the Company.
          Our Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your personal information when You
          use the Application or the Website and tells You about Your privacy
          rights and how the law protects You. Please read Our Privacy Policy
          carefully before using Our Service.
        </span>
      </p>
      <h2 className="c9" id="h.wfdjg359ubfo">
        <span className="c6">User Accounts</span>
      </h2>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          When You create an account with Us, You must provide Us information
          that is accurate, complete, and current at all times. Failure to do so
          constitutes a breach of the Terms, which may result in immediate
          termination of Your account on Our Service.
        </span>
      </p>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          You are responsible for safeguarding the password that You use to
          access the Service and for any activities or actions under Your
          password, whether Your password is with Our Service or a Third-Party
          Social Media Service.
        </span>
      </p>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          You agree not to disclose Your password to any third party. You must
          notify Us immediately upon becoming aware of any breach of security or
          unauthorized use of Your account.
        </span>
      </p>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          You may not use as a username the name of another person or entity or
          that is not lawfully available for use, a name or trademark that is
          subject to any rights of another person or entity other than You
          without appropriate authorization, or a name that is otherwise
          offensive, vulgar or obscene.
        </span>
      </p>
      <h2 className="c9" id="h.5bftua7wt79d">
        <span className="c6">Content</span>
      </h2>
      <h3 className="c5" id="h.cikbkqg9ll56">
        <span className="c2">Your Right to Post Content</span>
      </h3>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          Our Service allows You to post Content. You are responsible for the
          Content that You post to the Service, including its legality,
          reliability, and appropriateness.
        </span>
      </p>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          By posting Content to the Service, You grant Us the right and license
          to use, modify, publicly perform, publicly display, reproduce, and
          distribute such Content on and through the Service. You retain any and
          all of Your rights to any Content You submit, post or display on or
          through the Service and You are responsible for protecting those
          rights. You agree that this license includes the right for Us to make
          Your Content available to other users of the Service, who may also use
          Your Content subject to these Terms.
        </span>
      </p>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          You represent and warrant that: (i) the Content is Yours (You own it)
          or You have the right to use it and grant Us the rights and license as
          provided in these Terms, and (ii) the posting of Your Content on or
          through the Service does not violate the privacy rights, publicity
          rights, copyrights, contract rights or any other rights of any person.
        </span>
      </p>
      <h3 className="c5" id="h.32srtymoedmv">
        <span className="c2">Content Restrictions</span>
      </h3>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          The Company is not responsible for the content of the Service&#39;s
          users. You expressly understand and agree that You are solely
          responsible for the Content and for all activity that occurs under
          your account, whether done so by You or any third person using Your
          account.
        </span>
      </p>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          You may not transmit any Content that is unlawful, offensive,
          upsetting, intended to disgust, threatening, libelous, defamatory,
          obscene or otherwise objectionable. Examples of such objectionable
          Content include, but are not limited to, the following:
        </span>
      </p>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <ul className="c12 lst-kix_ohm196jyzq5s-0 start">
        <li className="c0 li-bullet-0">
          <span className="c1">Unlawful or promoting unlawful activity.</span>
        </li>
        <li className="c0 li-bullet-0">
          <span className="c1">
            Defamatory, discriminatory, or mean-spirited content, including
            references or commentary about religion, race, sexual orientation,
            gender, national/ethnic origin, or other targeted groups.
          </span>
        </li>
        <li className="c0 li-bullet-0">
          <span className="c1">
            Spam, machine &ndash; or randomly &ndash; generated, constituting
            unauthorized or unsolicited advertising, chain letters, any other
            form of unauthorized solicitation, or any form of lottery or
            gambling.
          </span>
        </li>
        <li className="c0 li-bullet-0">
          <span className="c1">
            Containing or installing any viruses, worms, malware, trojan horses,
            or other content that is designed or intended to disrupt, damage, or
            limit the functioning of any software, hardware or
            telecommunications equipment or to damage or obtain unauthorized
            access to any data or other information of a third person.
          </span>
        </li>
        <li className="c0 li-bullet-0">
          <span className="c1">
            Infringing on any proprietary rights of any party, including patent,
            trademark, trade secret, copyright, right of publicity or other
            rights.
          </span>
        </li>
        <li className="c0 li-bullet-0">
          <span className="c1">
            Impersonating any person or entity including the Company and its
            employees or representatives.
          </span>
        </li>
        <li className="c0 li-bullet-0">
          <span className="c1">Violating the privacy of any third person.</span>
        </li>
        <li className="c0 li-bullet-0">
          <span className="c1">False information and features.</span>
        </li>
      </ul>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          The Company reserves the right, but not the obligation, to, in its
          sole discretion, determine whether or not any Content is appropriate
          and complies with this Terms, refuse or remove this Content. The
          Company further reserves the right to make formatting and edits and
          change the manner of any Content. The Company can also limit or revoke
          the use of the Service if You post such objectionable Content. As the
          Company cannot control all content posted by users and/or third
          parties on the Service, you agree to use the Service at your own risk.
          You understand that by using the Service You may be exposed to content
          that You may find offensive, indecent, incorrect or objectionable, and
          You agree that under no circumstances will the Company be liable in
          any way for any content, including any errors or omissions in any
          content, or any loss or damage of any kind incurred as a result of
          your use of any content.
        </span>
      </p>
      <h3 className="c5" id="h.qkar4vwcptk">
        <span className="c2">Content Backups</span>
      </h3>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          Although regular backups of Content are performed, the Company does
          not guarantee there will be no loss or corruption of data.
        </span>
      </p>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          Corrupt or invalid backup points may be caused by, without limitation,
          Content that is corrupted prior to being backed up or that changes
          during the time a backup is performed.
        </span>
      </p>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          The Company will provide support and attempt to troubleshoot any known
          or discovered issues that may affect the backups of Content. But You
          acknowledge that the Company has no liability related to the integrity
          of Content or the failure to successfully restore Content to a usable
          state.
        </span>
      </p>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          You agree to maintain a complete and accurate copy of any Content in a
          location independent of the Service.
        </span>
      </p>
      <h2 className="c9" id="h.t5jcfp3eh0wr">
        <span className="c6">Copyright Policy</span>
      </h2>
      <h3 className="c5" id="h.3dvtallj64hq">
        <span className="c2">Intellectual Property Infringement</span>
      </h3>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          We respect the intellectual property rights of others. It is Our
          policy to respond to any claim that Content posted on the Service
          infringes a copyright or other intellectual property infringement of
          any person.
        </span>
      </p>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span>
          If You are a copyright owner, or authorized on behalf of one, and You
          believe that the copyrighted work has been copied in a way that
          constitutes copyright infringement that is taking place through the
          Service, You must submit Your notice in writing to the attention of
          our copyright agent, Mr Aron Cohen, via email (
        </span>
        <span className="c10">cohen [at] microweb [dot] ltd</span>
        <span className="c1">
          ) and include in Your notice a detailed description of the alleged
          infringement.
        </span>
      </p>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          You may be held accountable for damages (including costs and
          attorneys&#39; fees) for misrepresenting that any Content is
          infringing Your copyright.
        </span>
      </p>
      <h3 className="c5" id="h.opd6161ig1v3">
        <span className="c2">
          DMCA Notice and DMCA Procedure for Copyright Infringement Claims
        </span>
      </h3>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          You may submit a notification pursuant to the Digital Millennium
          Copyright Act (DMCA) by providing our Copyright Agent with the
          following information in writing (see 17 U.S.C 512(c)(3) for further
          detail):
        </span>
      </p>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <ul className="c12 lst-kix_lp8340jemihm-0 start">
        <li className="c0 li-bullet-0">
          <span className="c1">
            An electronic or physical signature of the person authorized to act
            on behalf of the owner of the copyright&#39;s interest.
          </span>
        </li>
        <li className="c0 li-bullet-0">
          <span className="c1">
            A description of the copyrighted work that You claim has been
            infringed, including the URL (i.e., web page address) of the
            location where the copyrighted work exists or a copy of the
            copyrighted work.
          </span>
        </li>
        <li className="c0 li-bullet-0">
          <span className="c1">
            Identification of the URL or other specific location on the Service
            where the material that You claim is infringing is located.
          </span>
        </li>
        <li className="c0 li-bullet-0">
          <span className="c1">
            Your address, telephone number, and email address.
          </span>
        </li>
        <li className="c0 li-bullet-0">
          <span className="c1">
            A statement by You that You have a good faith belief that the
            disputed use is not authorized by the copyright owner, its agent, or
            the law.
          </span>
        </li>
        <li className="c0 li-bullet-0">
          <span className="c1">
            A statement by You, made under penalty of perjury, that the above
            information in Your notice is accurate and that You are the
            copyright owner or authorized to act on the copyright owner&#39;s
            behalf.
          </span>
        </li>
      </ul>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span>
          You can contact our copyright agent, Mr Aron Cohen, via email (
        </span>
        <span className="c10">cohen [at] microweb [dot] ltd</span>
        <span className="c1">
          ). Upon receipt of a notification, the Company will take whatever
          action, in its sole discretion, it deems appropriate, including
          removal of the challenged content from the Service.
        </span>
      </p>
      <h2 className="c9" id="h.n4cxns97ju4b">
        <span className="c6">Intellectual Property</span>
      </h2>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          The Service and its original content (excluding Content provided by
          You or other users), features and functionality are and will remain
          the exclusive property of the Company and its licensors.
        </span>
      </p>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          The Service is protected by copyright, trademark, and other laws of
          both the Country and foreign countries.
        </span>
      </p>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          Our trademarks and trade dress may not be used in connection with any
          product or service without the prior written consent of the Company.
        </span>
      </p>
      <h2 className="c9" id="h.wtbg81z4qmxz">
        <span className="c6">Your Feedback to Us</span>
      </h2>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          You assign all rights, title and interest in any Feedback You provide
          the Company. If for any reason such assignment is ineffective, You
          agree to grant the Company a non-exclusive, perpetual, irrevocable,
          royalty free, worldwide right and license to use, reproduce, disclose,
          sub-license, distribute, modify and exploit such Feedback without
          restriction.
        </span>
      </p>
      <h2 className="c9" id="h.usxnlf1r1z0y">
        <span className="c6">Links to Other Websites</span>
      </h2>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          Our Service may contain links to third-party web sites or services
          that are not owned or controlled by the Company.
        </span>
      </p>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          The Company has no control over, and assumes no responsibility for,
          the content, privacy policies, or practices of any third party web
          sites or services. You further acknowledge and agree that the Company
          shall not be responsible or liable, directly or indirectly, for any
          damage or loss caused or alleged to be caused by or in connection with
          the use of or reliance on any such content, goods or services
          available on or through any such web sites or services.
        </span>
      </p>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          We strongly advise You to read the terms and conditions and privacy
          policies of any third-party web sites or services that You visit.
        </span>
      </p>
      <h2 className="c9" id="h.uk70aofltu4r">
        <span className="c6">Termination</span>
      </h2>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          We may terminate or suspend Your Account immediately, without prior
          notice or liability, for any reason whatsoever, including without
          limitation if You breach these Terms and Conditions.
        </span>
      </p>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          Upon termination, Your right to use the Service will cease
          immediately. If You wish to terminate Your Account, You may simply
          discontinue using the Service.
        </span>
      </p>
      <h2 className="c9" id="h.b0xbug1p4so9">
        <span className="c6">Limitation of Liability</span>
      </h2>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          Notwithstanding any damages that You might incur, the entire liability
          of the Company and any of its suppliers under any provision of this
          Terms and Your exclusive remedy for all of the foregoing shall be
          limited to the amount actually paid by You through the Service or 100
          USD if You haven&#39;t purchased anything through the Service.
        </span>
      </p>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          To the maximum extent permitted by applicable law, in no event shall
          the Company or its suppliers be liable for any special, incidental,
          indirect, or consequential damages whatsoever (including, but not
          limited to, damages for loss of profits, loss of data or other
          information, for business interruption, for personal injury, loss of
          privacy arising out of or in any way related to the use of or
          inability to use the Service, third-party software and/or third-party
          hardware used with the Service, or otherwise in connection with any
          provision of this Terms), even if the Company or any supplier has been
          advised of the possibility of such damages and even if the remedy
          fails of its essential purpose.
        </span>
      </p>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          Some states do not allow the exclusion of implied warranties or
          limitation of liability for incidental or consequential damages, which
          means that some of the above limitations may not apply. In these
          states, each party&#39;s liability will be limited to the greatest
          extent permitted by law.
        </span>
      </p>
      <h2 className="c9" id="h.u7mz8ciz3z6b">
        <span className="c6">
          &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; Disclaimer
        </span>
      </h2>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          The Service is provided to You &quot;AS IS&quot; and &quot;AS
          AVAILABLE&quot; and with all faults and defects without warranty of
          any kind. To the maximum extent permitted under applicable law, the
          Company, on its own behalf and on behalf of its Affiliates and its and
          their respective licensors and service providers, expressly disclaims
          all warranties, whether express, implied, statutory or otherwise, with
          respect to the Service, including all implied warranties of
          merchantability, fitness for a particular purpose, title and
          non-infringement, and warranties that may arise out of course of
          dealing, course of performance, usage or trade practice. Without
          limitation to the foregoing, the Company provides no warranty or
          undertaking, and makes no representation of any kind that the Service
          will meet Your requirements, achieve any intended results, be
          compatible or work with any other software, applications, systems or
          services, operate without interruption, meet any performance or
          reliability standards or be error free or that any errors or defects
          can or will be corrected.
        </span>
      </p>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          Without limiting the foregoing, neither the Company nor any of the
          company&#39;s provider makes any representation or warranty of any
          kind, express or implied: (i) as to the operation or availability of
          the Service, or the information, content, and materials or products
          included thereon; (ii) that the Service will be uninterrupted or
          error-free; (iii) as to the accuracy, reliability, or currency of any
          information or content provided through the Service; or (iv) that the
          Service, its servers, the content, or e-mails sent from or on behalf
          of the Company are free of viruses, scripts, trojan horses, worms,
          malware, timebombs or other harmful components.
        </span>
      </p>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          Some jurisdictions do not allow the exclusion of certain types of
          warranties or limitations on applicable statutory rights of a
          consumer, so some or all of the above exclusions and limitations may
          not apply to You. But in such a case the exclusions and limitations
          set forth in this section shall be applied to the greatest extent
          enforceable under applicable law.
        </span>
      </p>
      <h2 className="c9" id="h.qel6jfdzxlp7">
        <span className="c6">Governing Law</span>
      </h2>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          The laws of the Country, excluding its conflicts of law rules, shall
          govern this Terms and Your use of the Service. Your use of the
          Application may also be subject to other local, state, national, or
          international laws.
        </span>
      </p>
      <h2 className="c9" id="h.h3owinkvr9l0">
        <span className="c6">Disputes Resolution</span>
      </h2>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          If You have any concern or dispute about the Service, You agree to
          first try to resolve the dispute informally by contacting the Company.
        </span>
      </p>
      <h2 className="c9" id="h.5ouq9sqrksvk">
        <span className="c6">For European Union (EU) Users</span>
      </h2>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          If You are a European Union consumer, you will benefit from any
          mandatory provisions of the law of the country in which you are
          resident in.
        </span>
      </p>
      <h2 className="c9" id="h.xhustptbcch9">
        <span className="c6">United States Legal Compliance</span>
      </h2>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          You represent and warrant that (i) You are not located in a country
          that is subject to the United States government embargo, or that has
          been designated by the United States government as a &quot;terrorist
          supporting&quot; country, and (ii) You are not listed on any United
          States government list of prohibited or restricted parties.
        </span>
      </p>
      <h2 className="c9" id="h.j7az70ildgzz">
        <span className="c6">Severability and Waiver</span>
      </h2>
      <h3 className="c5" id="h.htphlf2z8sx3">
        <span className="c2">Severability</span>
      </h3>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          If any provision of these Terms is held to be unenforceable or
          invalid, such provision will be changed and interpreted to accomplish
          the objectives of such provision to the greatest extent possible under
          applicable law and the remaining provisions will continue in full
          force and effect.
        </span>
      </p>
      <h3 className="c5" id="h.2rqh2jy7y8rg">
        <span className="c2">Waiver</span>
      </h3>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          Except as provided herein, the failure to exercise a right or to
          require performance of an obligation under these Terms shall not
          effect a party&#39;s ability to exercise such right or require such
          performance at any time thereafter nor shall the waiver of a breach
          constitute a waiver of any subsequent breach.
        </span>
      </p>
      <h2 className="c9" id="h.6z679psa6ica">
        <span className="c6">Changes to These Terms and Conditions</span>
      </h2>
      <p className="c3">
        <span className="c1">
          We reserve the right, at Our sole discretion, to modify or replace
          these Terms at any time. If a revision is material We will make
          reasonable efforts to provide at least 30 days&#39; notice prior to
          any new terms taking effect. What constitutes a material change will
          be determined at Our sole discretion.
        </span>
      </p>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          By continuing to access or use Our Service after those revisions
          become effective, You agree to be bound by the revised terms. If You
          do not agree to the new terms, in whole or in part, please stop using
          the website and the Service.
        </span>
      </p>
      <h2 className="c9" id="h.c5phrcobqvac">
        <span className="c6">Contact Us</span>
      </h2>
      <p className="c3 c4">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          If you have any questions about these Terms and Conditions, You can
          contact us at coverbot [at] microweb [dot] ltd.
        </span>
      </p>
    </div>
  );
}
