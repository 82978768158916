// React
import { useState, useEffect } from 'react';

// Components
import CoverLetterView from '../../components/CoverLetterView/CoverLetterView';
import {
  GenericError,
  DeleteCoverLetter,
} from '../../components/AlertDialog/AlertDialog';
import Spinner from '../../components/Spinner/Spinner';

// Hooks
import { useUser } from '../../hooks/useUserData';

// Router
import { useNavigate } from 'react-router-dom';

// Styles
import './DashboardPage.scss';

interface coverLetter {
  id: number;
  jobTitle: string;
  companyName: string;
}

export default function DashboardPage() {
  const [coverLetters, setCoverLetters] = useState<coverLetter[] | null>(null);
  const [coverLetterContent, setCoverLetterContent] = useState<string>('');
  const [coverLetterToDelete, setCoverLetterToDelete] = useState<number>(0);
  const [genericError, setGenericError] = useState<boolean>(false);
  const { userData, logOut } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userData) return;

    if (!localStorage.getItem('U532D474')) {
      navigate('/signin');

      return;
    }

    (async () => {
      fetch(`${process.env.REACT_APP_SERVER_URL}/coverletters`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userData?.split(':')[0].substring(1)}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success === 'ok') {
            setCoverLetters(data.coverLetters);

            return;
          } else {
            logOut();
          }
        })
        .catch(() => {
          setGenericError(true);
        });
    })();
  }, [userData, navigate, logOut]);

  const getCoverLetter = (id: number) => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/coverletters/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userData?.split(':')[0].substring(1)}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCoverLetterContent(data.coverLetter.coverLetterContent);
      })
      .catch(() => {
        setGenericError(true);
      });
  };

  const deleteCoverLetter = () => {
    fetch(
      `${process.env.REACT_APP_SERVER_URL}/coverletters/${coverLetterToDelete}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userData?.split(':')[0].substring(1)}`,
        },
      }
    )
      .then((res) => res.json())
      .then(() => {
        if (!coverLetters) return;

        setCoverLetters(
          coverLetters.filter((letter) => letter.id !== coverLetterToDelete)
        );
        setCoverLetterToDelete(0);
      })
      .catch(() => {
        setGenericError(true);
      });
  };

  return (
    <div className="dashboard">
      <div className="dashboard__container">
        <h3>Dashboard</h3>
        {userData && coverLetters ? (
          <ul>
            {coverLetters.length > 0 ? (
              coverLetters.map((oneCoverLetter) => (
                <li key={oneCoverLetter?.id}>
                  <div className="dashboard__job-data">
                    <p>
                      <strong>{oneCoverLetter.jobTitle}</strong> at{' '}
                      {oneCoverLetter.companyName}
                    </p>
                  </div>
                  <div className="dashboard__job-buttons">
                    <button onClick={() => getCoverLetter(oneCoverLetter.id)}>
                      <svg viewBox="0 96 960 960">
                        <path d="M319 806.48h322v-62.63H319v62.63Zm0-170h322v-62.63H319v62.63Zm-96.85 345.5q-27.6 0-47.86-20.27-20.27-20.26-20.27-47.86v-675.7q0-27.7 20.27-48.03 20.26-20.34 47.86-20.34h361.48l222.59 222.59v521.48q0 27.6-20.34 47.86-20.34 20.27-48.03 20.27h-515.7Zm326.7-557.83v-186h-326.7v675.7h515.7v-489.7h-189Zm-326.7-186v186-186 675.7-675.7Z" />
                      </svg>
                    </button>
                    <button
                      onClick={() => setCoverLetterToDelete(oneCoverLetter.id)}
                    >
                      <svg viewBox="0 96 960 960">
                        <path d="M259.09 941.98q-28.45 0-48.41-19.9-19.96-19.88-19.96-48.23V307.9h-45.07v-68.13h198.28v-34.3h271.9v34.3h198.52v68.13h-45.07v565.94q0 27.6-20.33 47.86-20.34 20.27-48.04 20.27H259.1ZM700.9 307.9H259.1v565.94H700.9V307.9ZM363.9 789.76h64.07v-399h-64.07v399Zm168.15 0h64.3v-399h-64.3v399ZM259.1 307.91v565.94V307.9Z" />
                      </svg>
                    </button>
                  </div>
                </li>
              ))
            ) : (
              <div className="dashboard__none">
                <p>
                  You have not created any cover letters yet. <br /> Click on
                  the button below to get started.
                </p>
              </div>
            )}
          </ul>
        ) : (
          <Spinner />
        )}
      </div>
      <button
        className="dashboard__create-new-job"
        onClick={() => navigate('/generate')}
      >
        New Cover Letter
      </button>

      {Boolean(coverLetterToDelete) && (
        <DeleteCoverLetter
          handleConfirm={deleteCoverLetter}
          isOpen={Boolean(coverLetterToDelete)}
          onClose={() => setCoverLetterToDelete(0)}
        />
      )}

      {coverLetterContent && (
        <CoverLetterView
          coverLetterData={coverLetterContent}
          isOpen={Boolean(coverLetterContent)}
          onClose={() => setCoverLetterContent('')}
        />
      )}

      {genericError && (
        <GenericError
          isOpen={Boolean(genericError)}
          onClose={() => setGenericError(false)}
        />
      )}
    </div>
  );
}
