// Router
import { Routes, Route, Navigate } from 'react-router-dom';

// Hooks & Context
import { UserProvider } from './hooks/useUserData';

// Components
import NavBar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer';
import Auth from './components/Auth/Auth';

// Pages
import HomePage from './pages/Home/HomePage';
import GeneratePage from './pages/Generate/GeneratePage';
import SignInPage from './pages/SignIn/SignInPage';
import HelpPage from './pages/Help/HelpPage';
import DashboardPage from './pages/Dashboard/DashboardPage';
import AccountPage from './pages/Account/AccountPage';
import BuyPage from './pages/Buy/BuyPage';
import Terms from './pages/Terms/Terms';
import Privacy from './pages/Privacy/Privacy';

export default function App() {
  return (
    <UserProvider>
      <div className="app">
        <NavBar />
        <div className="app__container">
          <Routes>
            <Route index element={<HomePage />} />
            <Route path="/generate" element={<GeneratePage />} />
            <Route path="/signin/*" element={<SignInPage />} />
            <Route path="/help" element={<HelpPage />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/account" element={<AccountPage />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/auth/:x" element={<Auth />} />
            <Route path="/buy/:x" element={<BuyPage />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </UserProvider>
  );
}
