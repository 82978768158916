// Styles
import './Footer.scss';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer>
      <span>© CoverBot {new Date().getFullYear()}</span>
      <ul>
        <li>
          <Link to="/terms">Terms</Link>
        </li>
        <li>
          <Link to="/privacy">Privacy</Link>
        </li>
        <li>
          <Link to="/help">Support</Link>
        </li>
      </ul>
    </footer>
  );
}
