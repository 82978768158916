import { useUser } from '../../hooks/useUserData';
import { useEffect, useState } from 'react';
import Spinner from '../../components/Spinner/Spinner';
import { useLocation, useNavigate } from 'react-router-dom';
import { GenericError } from '../../components/AlertDialog/AlertDialog';

//Styles
import './SignInPage.scss';

export default function SignInPage() {
  const { userData, logOut } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const [genericError, setGenericError] = useState<boolean>(false);

  useEffect(() => {
    if (!location) return;

    const allowedLocations = ['/signin', '/signin/e', '/signin/', '/signin/e/'];

    if (!allowedLocations.includes(location.pathname)) {
      navigate('/');

      return;
    }

    if (
      location.pathname === '/signin/e' ||
      location.pathname === '/signin/e/'
    ) {
      setGenericError(true);

      return;
    }
  }, [location, navigate]);

  useEffect(() => {
    if (!userData) return;

    logOut();
  }, [userData, logOut]);

  return (
    <div className="login">
      {!userData ? (
        <>
          <a href={`${process.env.REACT_APP_SERVER_URL}/auth/google`}>
            <button>
              {' '}
              <svg aria-label="Google" viewBox="0 0 512 512">
                <path
                  fill="#34a853"
                  d="M90 341a192 192 0 0 0 296 59v-48h-62c-53 35-141 22-171-60"
                />
                <path
                  fill="#4285f4"
                  d="M386 400a140 175 0 0 0 53-179H260v74h102q-7 37-38 57"
                />
                <path
                  fill="#fbbc02"
                  d="M90 341a208 200 0 0 1 0-171l63 49q-12 37 0 73"
                />
                <path
                  fill="#ea4335"
                  d="M153 219c22-69 116-109 179-50l55-54c-78-75-230-72-297 55"
                />
              </svg>{' '}
              <span>Sign In With Google</span>
            </button>
          </a>
          <a href={`${process.env.REACT_APP_SERVER_URL}/auth/linkedin`}>
            <button>
              {' '}
              <svg aria-label="LinkedIn" viewBox="0 0 512 512" fill="#0077b5">
                <circle cx="142" cy="138" r="37" />
                <path
                  stroke="#0077b5"
                  strokeWidth="66"
                  d="M244 194v198M142 194v198"
                />
                <path d="M276 282c0-20 13-40 36-40 24 0 33 18 33 45v105h66V279c0-61-32-89-76-89-34 0-51 19-59 32" />
              </svg>{' '}
              <span>Sign In With LinkedIn</span>
            </button>
          </a>
        </>
      ) : (
        <Spinner />
      )}

      {genericError && (
        <GenericError
          isOpen={Boolean(genericError)}
          onClose={() => setGenericError(false)}
        />
      )}
    </div>
  );
}
