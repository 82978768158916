// Assets
import one from './1.png';
import two from './2.png';

// Router
import { Link } from 'react-router-dom';

// Hooks
import { useUser } from '../../hooks/useUserData';

// Styles
import './HomePage.scss';

export default function HomePage() {
  const { userData } = useUser();

  return (
    <div className="home">
      <section className="home__hero">
        <div className="home__hero-text">
          <h1 className="home__hero-title">
            AI-Generated
            <br />
            Covering Letters
          </h1>
          <p className="home__hero-desc">
            Get a job in 2024 with a cover letter that's unique to you. <br />
            Fill in the details, add your CV and let us do the work.
          </p>
          <div className="home__buttons">
            <Link to="/generate">
              <button className="home__button">Get Started for Free</button>
            </Link>
            <div className="home__trial">
              <span>No Credit Card Required</span>
            </div>
          </div>
        </div>
        <div className="home__steps">
          <div className="home__step">
            <h3>📝 Fill In The Details</h3>
            <p>All we need is the job title, description and company name.</p>
          </div>
          <div className="home__step">
            <h3>📂 Add Your CV</h3>
            <p>
              Upload your CV in PDF format. We'll extract the relevant
              information.
            </p>
          </div>
          <div className="home__step">
            <h3>🤖 Get Your Result</h3>
            <p>We'll generate a cover letter for you in a matter of seconds.</p>
          </div>
        </div>
      </section>

      <section id="steps" className="home__about">
        <div className="home__about-img">
          <img alt="random" src={one} />
        </div>

        <div className="home__about-txt">
          <h1>What is CoverBot?</h1>
          <p>
            CoverBot is a web application that generates cover letters. It uses
            AI to extract the relevant information from your CV and job
            description and then uses that information to generate a cover
            letter that's unique to you.
          </p>
        </div>
      </section>

      <section className="home__pros">
        <div className="home__pros-info">
          <h1>🔍 Features</h1>
          <h2>
            CoverBot is fast, accurate and reliable. With its intuitive
            interface and advanced algorithms, you can effortlessly create a
            professional-grade cover letter in seconds.
          </h2>
        </div>

        <div className="home__pros-container">
          <div className="home__pro">
            <h3>🚀 Fast</h3>
            <p>
              On average, CoverBot takes between 5-10 seconds to generate a
              personalised cover letter.
            </p>
          </div>
          <div className="home__pro">
            <h3>🎯 Accurate</h3>
            <p>
              CoverBot matches key skills from your CV to the job description,
              highlighting your strengths.
            </p>
          </div>
          <div className="home__pro">
            <h3>🛠️ Reliable</h3>
            <p>
              With 24/7 availability and an ever improving process, you can
              count on CoverBot to get the job done.
            </p>
          </div>
        </div>
      </section>

      <section id="steps" className="home__about home__about--reverse">
        <div className="home__about-img">
          <img alt="random" src={two} />
        </div>

        <div className="home__about-txt">
          <h1>Why CoverBot?</h1>
          <p>
            Writing covering letters is tedious and time-consuming. CoverBot
            takes the hassle out of it by providing personalised and compelling
            cover letters that highlight your skills, experience, and
            achievements.
          </p>
        </div>
      </section>

      <section className="home__pros">
        <div className="home__pros-info">
          <h1>🎁 3 Free Cover Letters</h1>
          <h2>
            New users are currently entitled to three free cover letters. You
            can then pay per use or buy lifetime access, no subscriptions.
          </h2>
        </div>
        {!userData && (
          <div className="home__buttons">
            <Link to="/signin">
              <button className="home__button">
                Sign In Now to Get Started for Free
              </button>
            </Link>
          </div>
        )}

        {/* <div className="home__cards">
          <div className="home__buy">
            <div className="home__buy-container">
              <h2>Starter</h2>
              <h2>$0.00</h2>
              <p>
                3 Cover <br />
                Letters
              </p>
            </div>
            <button
              className="home__best-buy"
              onClick={() => {
                userData ? navigate('/signin') : navigate('/signin');
              }}
            >
              Sign In
            </button>
          </div>
          <div className="home__buy">
            <div className="home__buy-container">
              <h2>Plus </h2>
              <h2>$4.99</h2>
              <p>
                10 Cover <br />
                Letters
              </p>
            </div>
            <button
              onClick={() => {
                userData ? navigate('/account') : navigate('/signin');
              }}
            >
              Buy Now
            </button>
          </div>
          <div className="home__buy">
            <div className="home__buy-container">
              <h2>Pro</h2>
              <h2>$9.99</h2>
              <p>
                25 Cover <br />
                Letters
              </p>
            </div>
            <button
              onClick={() => {
                userData ? navigate('/account') : navigate('/signin');
              }}
            >
              Buy Now
            </button>
          </div>
        </div> */}
      </section>

      <section className="home__better">
        <h1>🔄 Better Input, Better Results</h1>
        <h2>
          The quality of your cover letter largely hinges on the quality of your
          job description and CV. Improving the quality of these inputs will
          lead to better overall results.
        </h2>
        <div className="home__better-container">
          <div className="home__case">
            <h3>✅ Good Quality</h3>
            <ul>
              <li>Correct job title and company name</li>
              <li>CV with relevant skills & experience</li>
              <li>Accurate job description</li>
            </ul>
          </div>
          <div className="home__case">
            <h3>❌ Mediocre Quality</h3>
            <ul>
              <li>CV with irrelevant skills & experience</li>
              <li>Incorrect job title and company name</li>
              <li>Inaccurate job description</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="home__pros" style={{ textAlign: 'center' }}>
        <div className="home__pros-info">
          <h1>🔒 100% Secure</h1>
          <h2>
            We take privacy and security extremely seriously and employ a number
            of measures to ensure your peace of mind.
          </h2>
        </div>
        <div className="home__pros-container">
          <div className="home__pro">
            <h3>Fully Encrypted</h3>
            <p>
              Your information cannot be accessed by anyone else other than
              yourself.
            </p>
          </div>
          <div className="home__pro">
            <h3>Fully Private</h3>
            <p>
              Input data is parsed locally and destroyed when the output is
              created.
            </p>
          </div>
          <div className="home__pro">
            <h3>Fully Secure</h3>
            <p>Our robust measures stand guard, guaranteeing your security.</p>
          </div>
        </div>
      </section>

      <section id="steps" className="home__about">
        <div className="home__about-txt">
          <h1>Get Started</h1>
          <p>
            <Link to="/generate">Click here</Link> to generate your first
            covering letter.
            <br /> Head over to our <Link to="/help">help page</Link> for more
            information.
          </p>
        </div>
      </section>
    </div>
  );
}
