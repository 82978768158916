import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
  SetStateAction,
  Dispatch,
} from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

interface IUserContext {
  userData: string | null;
  setUserData: Dispatch<SetStateAction<string | null>>;
  logOut: () => void;
}

const UserContext = createContext<IUserContext>(null as any);

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [userData, setUserData] = useState<string | null>(null);
  const logOut = () => {
    localStorage.removeItem('U532D474');
    setUserData(null);
    navigate('/signin');
  };

  // check if token is in local storage on run and if so, set it to state
  useEffect(() => {
    if (!localStorage.getItem('U532D474')) return;

    const _uData = JSON.stringify(localStorage.getItem('U532D474')) || 'none';

    if (_uData === 'none' || !_uData) return;

    setUserData(_uData);
  }, []);

  // check if token is expired on every route change
  useEffect(() => {
    if (!userData) return;

    const _uData = localStorage.getItem('U532D474') || 'none';

    if (!_uData || _uData === 'none') return;

    const expiryDate = _uData.split(':')[1];

    if (Number(expiryDate) < Date.now()) {
      logOut();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, userData]);

  return (
    <UserContext.Provider value={{ userData, setUserData, logOut }}>
      {children}
    </UserContext.Provider>
  );
};
