import { Link } from 'react-router-dom';
import './NavBar.scss';
import logo from './logo.svg';
import { useUser } from '../../hooks/useUserData';

export default function NavBar() {
  const { userData } = useUser();

  return (
    <nav>
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="" />
        </Link>
      </div>

      <ul>
        <li>
          <Link to="/generate">
            <svg viewBox="0 96 960 960">
              <path d="M319 806h322v-60H319v60Zm0-170h322v-60H319v60Zm-99 340q-24 0-42-18t-18-42V236q0-24 18-42t42-18h361l219 219v521q0 24-18 42t-42 18H220Zm331-554V236H220v680h520V422H551ZM220 236v186-186 680-680Z" />
            </svg>
            <span>Create</span>
          </Link>
        </li>
        {userData ? (
          <>
            <li>
              <Link to="/dashboard">
                <svg viewBox="0 96 960 960">
                  <path d="M182.15 941.98q-28.1 0-48.12-20.02-20-20-20-48.11v-595.7q0-28.2 20-48.28t48.12-20.09h595.7q28.2 0 48.28 20.09t20.09 48.28v595.7q0 28.1-20.09 48.12-20.08 20-48.28 20h-595.7ZM410 873.85V606H182.15v267.85H410Zm60 0h307.85V606H470v267.85ZM182.15 546h595.7V278.15h-595.7V546Z" />
                </svg>
                <span>Dashboard</span>
              </Link>
            </li>
            <li>
              <Link to="/account">
                <svg viewBox="0 96 960 960">
                  <path d="M222.96 798.37q62.76-43.52 124.52-66.78 61.76-23.26 132.62-23.26 70.87 0 133.25 23.44 62.37 23.45 124.7 66.6 43.52-54.24 61.66-108.15Q817.85 636.3 817.85 576q0-143.86-96.98-240.86-96.98-96.99-240.83-96.99t-240.87 97Q142.15 432.13 142.15 576q0 60.28 18.53 114.14t62.28 108.23Zm256.85-190.7q-58.56 0-98.4-40.04t-39.84-98.46q0-58.4 40.02-98.39 40.03-39.98 98.6-39.98 58.57 0 98.4 40.17 39.84 40.16 39.84 98.58 0 58.4-40.02 98.27-40.03 39.85-98.6 39.85Zm.52 374.3q-83.53 0-157.57-31.92t-129.47-87.33q-55.4-55.4-87.34-129.24-31.93-73.83-31.93-157.77 0-83.67 31.98-157.36 31.98-73.7 87.31-129.04 55.34-55.33 129.18-87.43 73.84-32.1 157.8-32.1 83.67 0 157.36 32.1 73.7 32.1 129.03 87.43 55.34 55.34 87.44 129.09 32.1 73.75 32.1 157.27 0 83.53-32.1 157.6T766.68 862.7Q711.35 918.02 637.6 950q-73.75 31.98-157.27 31.98Zm-.33-68.12q54.28 0 105.59-15.52t102.3-54.8q-51.24-35.77-102.8-54.17-51.57-18.4-105.09-18.4t-104.97 18.4q-51.44 18.4-102.68 54.16 51 39.28 102.18 54.8 51.19 15.53 105.47 15.53Zm0-369.05q33.25 0 54.4-21.02 21.17-21.02 21.17-54.42t-21.16-54.54q-21.16-21.15-54.41-21.15t-54.4 21.14q-21.17 21.15-21.17 54.55t21.16 54.42Q446.75 544.8 480 544.8Zm0-75.56Zm.24 373.28Z" />
                </svg>
                <span>Account</span>
              </Link>
            </li>
          </>
        ) : (
          <>
            <li className="nav__signin">
              <Link to="/signin">
                <svg viewBox="0 96 960 960">
                  <path d="M487.56 941.98v-68.13h292.92v-595.7H487.56v-68.37h292.92q27.35 0 47.74 20.34t20.39 48.03v595.7q0 27.6-20.4 47.86-20.38 20.27-47.73 20.27H487.56ZM405.75 759.6l-48.98-48.5 100.8-101.05H111.4v-68.13h344.18L354.76 440.9l48.97-48.5L588.34 577l-182.6 182.6Z" />
                </svg>
                <span>Sign in</span>
              </Link>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
}
