import './BuyPage.scss';

// React
import { useEffect } from 'react';

// Router
import { useParams, useNavigate } from 'react-router-dom';

export default function HelpPage() {
  const { x } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (x !== 'success' && x !== 'cancel') {
      navigate('/');

      return;
    }

    const timeout = setTimeout(() => {
      navigate('/account');
    }, 3000);

    return () => clearTimeout(timeout);
  }, [navigate, x]);

  return (
    <div className="buy">
      <h3>Checkout</h3>

      {x === 'success' && (
        <div className="buy__success">
          <h4>Payment successful!</h4>
          <p>Redirecting you to your account page...</p>
        </div>
      )}

      {x === 'cancel' && (
        <div className="buy__cancelled">
          <h4>Payment cancelled!</h4>
          <p>Redirecting you to your account page...</p>
        </div>
      )}
    </div>
  );
}
